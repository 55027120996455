//Admin page side navbar styles starts
.ad-left-wrapper {
    position: fixed;
    flex: 0 0 56px;
    height: 100%;
    z-index: 99;
    .ps-sidebar-root.ps-collapsed {
        width: 58px;
        min-width: 58px;
        height: 100%;
        color: $grey-color7;
        .app-version-text{
            font-size: 12px;
            color:$white-color;
        }
        .app-version-text{
            padding-right:5px;
        }
        &.nav-expanded{
            padding-right:0;
        }
        .ps-sidebar-footer{
            .sidebar-btn-wrapper{
                cursor: pointer;
                .foot-icon-text{
                    display: none;
                    width: 110px;
                    height: auto;
                }
            }
        }

        // &:hover{
        //     width: 173px;
        //     min-width: 173px;
        //     .ps-sidebar-footer{
        //         .sidebar-btn-wrapper{
        //             .foot-icon{
        //                 display: none;
        //             }
        //             .foot-icon-text{
        //                 display: block;
        //                 margin:10px auto;
        //             }
        //         }
        //     }
        // }
        .ps-sidebar-container{
            background-color:$blue-color1;
            .ps-sidebar-layout {
                overflow: unset;
                height: 100%;
                position: relative;
                display: flex;
                flex-direction: column;
                z-index: 101;
            }
            .ps-sidebar-header{
                height:8px;
                background-image: linear-gradient(to right, #CF3F3D , #6E4A66, #2A5183);
            }
            .ps-sidebar-content{
                padding-top: 10px;
                overflow: auto;
                flex-grow: 1;
                .ps-menu-root {
                    max-height: calc(100% - 80px);
                    overflow: auto;
                    &::-webkit-scrollbar { width: 0; }
                }
            }
            .ps-sidebar-layout .ps-sidebar-footer{ 
                padding-bottom: 15px;
                text-align: center;
                border: none;
                a { padding: 0 4px 0 0; }
            }
        } 
        .ps-menu-root .ps-menuitem-root{
            font-size: pxToRem(16px);
            .ps-menu-button{
                position: relative;
                display: flex;
                padding: 12px 15px;
                font-size: pxToRem(16px);
                &:hover{
                    color: $white-color;
                    background-color: transparent;
                    font-weight: 500;
                    .navIcon:before{
                        color:$white-color;
                    }
                }
                .ps-menu-label{
                    display: flex;
                    align-items: center;
                    padding: 1px;
                }
                .chat-new-notification{
                    position: absolute;
                    top: 8px;
                    left: 30px;
                    &::before {
                        content: "";
                        display: block;
                        width: 8px;
                        height: 8px;
                        background: $red-color;
                        z-index: 99;
                        border-radius: 50%;
                    }
                }
                .navChat{
                    @include icomoon('Chat',20px);
                }
                .navSchedule{
                    @include icomoon('Schedule',20px);
                }
                .navMaps{
                    @include icomoon('Maps',20px);
                }
                .navAdmin{
                    @include icomoon('Admin',20px);
                }
                .navGlobalSearch{
                    @include icomoon('global-search',20px);
                }
                .navUserGuide {
                    @include icomoon('help',20px);
                    &::before { color: #859099; }
                }
                .navReports{
                    @include icomoon('monitoring',20px);
                    &::before { color: #859099; }
                }
                .navStatOrder{
                    @include icomoon('on-call',20px);
                    &::before { color: #859099; }
                }
                .navHomeHeath{
                    @include icomoon('home-health',20px);
                    &::before { color: #859099; }
                }
                .navAdminChat{
                    @include icomoon('admin-chat',20px);
                    &::before { color: #859099; }
                }
                .navCareHomeSchedule {
                    @include icomoon('care-at-home-schedule',20px);
                    &::before { color: #859099; }
                    
                }
            }
            &.active{
                color: $white-color;
                font-weight: 500;
                .navIcon:before{
                    color:$white-color;
                }
            }
        }
        
    }
    .nav-expanded {
        width: 180px !important;
        min-width: 180px !important;
        .ps-sidebar-footer{
            .sidebar-btn-wrapper{
                .foot-icon{
                    display: none;
                }
                .foot-icon-text{
                    display: block !important;
                    margin:10px auto;
                }
            }
        }
    }
}
.profile-dropdown,
.show>.profile-dropdown,
.profile-dropdown:focus {
    padding: 0;
    background: transparent !important;
    border: none;
    box-shadow: none !important;
    &::after { display: none; }
}
