.scheduler-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-left {
    flex-basis: 240px;
    max-width: 240px;

    @include breakpoint(max1024) {
      flex-basis: 160px;
      max-width: 160px;
    }
  }

  .scheduler-filters {
    display: flex;
    align-items: center;
    flex-basis: 52%;
    max-width: 52%;

    .filter-title {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: capitalize;
      &:focus,
      &:active {
        background-color: transparent;
        color: #000000;
      }
      .service-icon {
        display: flex;
        justify-content: center;
        padding-left: 10px;
        @include icomoon("arrow-down", 5px);

        &::before {
          color: $blue-color11;
        }
      }
    }

    .st-filter {
      min-width: 145px;

      .filter-title {
        padding: 5px;
      }
    }

    .day-filter {
      min-width: 223px;
      //margin-right: 5px;
      text-align: right;

      .react-datepicker {
        border-radius: 8px;
        border: 1px solid rgba(18, 18, 18, 0.2);
      }

      .react-datepicker__header {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }

      .react-datepicker-popper {
        z-index: 55;
      }

      .service-icon {
        position: absolute;
        right: 0;
        top: -2px;
      }

      .react-datepicker__day--selected,
      .react-datepicker__day--keyboard-selected {
        background-color: $dark-blue-color;
      }
    }
  }

  .dropdown {
    display: flex;
    flex-basis: 7%;
    justify-content: flex-end;

    @include breakpoint(max1024) {
      flex-basis: 5%;
    }
  }
  .copy-cal-btn {
    min-width: 163px;
    margin-right: 10px;

    @include breakpoint(max1024) {
      min-width: 143px;
    }

    span {
      padding-right: 10px;
      line-height: 1;
    }

    @include breakpoint(max1024) {
      min-width: auto;
      font-size: 0;

      span {
        padding-right: 0;
      }
    }

    &:disabled span:before {
      color: $label-color;
    }
  }
}

.sch-date-picker {
  position: relative;
  padding: 0.375rem 2rem 0.375rem 0.75rem;
  border: none;
  outline: none;
  background-color: transparent;
  @include icomoon("arrow-down", 5px);

  &:hover {
    cursor: pointer;
  }

  &:focus {
    border-radius: 0.25rem;
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
  }

  &::before {
    position: absolute;
    right: 12px;
    top: 13px;
    color: $grey-color;
  }
}

.scheduler-tabs-wrapper {
  height: calc(100% - 38px);

  .top-search-box {
    position: relative;
    display: flex;
    padding-left: 20px;
  }

  .sch-filter-top {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    .filter-type {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 155px;
      margin-right: 24px;
      @include icomoon("arrow-down", 5px);

      &::before {
        position: absolute;
        right: 15px;
        color: $grey-color;
      }

      &.show {
        &::before {
          transform: rotate(180deg);
          transition: transform 300ms ease;
          color: $light-blue-color;
        }
      }

      button {
        width: 100%;
        height: 34px;
        font-size: pxToRem(14px);
        font-family: $roboto-regular;
        padding: 0 10px;
        line-height: 20px;
        letter-spacing: .2px;
        text-align: left;
        text-transform: capitalize;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border: 1px solid $grey-color7;
        background: transparent;
        color: $black-color;

        &::after {
          border: none;
        }
      }

      .dropdown-menu {
        padding: 5px;

        li {
          font-size: pxToRem(14px);
          font-family: $roboto-regular;
          padding: 10px 5px;
          cursor: pointer;

          &:hover,
          &:focus {
            color: $dark-grey-color;
            background-color: rgba(0, 0, 0, 0.08) !important;
          }

          &.active {
            color: $dark-grey-color;
            background-color: rgba(0, 0, 0, 0.08) !important;
          }
        }
      }
    }
  }

  .search-box {
    width: 274px;
    height: 34px;
    padding: 0 45px 0 10px;
    font-size: pxToRem(14px);
    font-family: $roboto-regular;
    line-height: 20px;
    letter-spacing: .18px;
    border-radius: 4px;
    border: 1px solid $grey-color7;
    background-color: $white-color;
    color: $dark-grey-color;

    &:focus,
    &:focus-visible {
      box-shadow: 0 0 0 4px rgba(13, 110, 253, .25);
      outline: none;
    }
  }

  .search-icon {
    position: absolute;
    height: 100%;
    right: 0;
    top: -1px;
    padding: 10px;
    border-left: 1px solid $grey-color7;
    @include icomoon("Search", 17px);
    &:hover { cursor: pointer; }

    &::before {
      color: $grey-color;
    }
  }

  .close-icon {
    position: absolute;
    height: 100%;
    right: 38px;
    top: -1px;
    padding: 10px;
    // border-left: 1px solid $grey-color7;
    @include icomoon("close", 15px);
    &:hover { cursor: pointer; }
    &::before {
      color: $grey-color11;
    }
  }
}

.scheduler-tabs {
  padding-bottom: 0;
  border-radius: 5px;

  &.nav-tabs:before {
    border: 0;
  }

  li {
    background-color: $grey-color9;

    .nav-link {
      font-family: $roboto-medium;
      font-size: pxToRem(12px);
      color: $blue-color11;
      border-radius: 5px;
    }

    .nav-link.active {
      color: $white-color;
      border: none;
      border-radius: 5px;

      &:before {
        background-color: transparent;
      }

    }

    &:nth-child(3) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:nth-child(1) {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:nth-child(1) .nav-link.active {
      background-color: $green-color3;
    }

    &:nth-child(2) .nav-link.active {
      background-color: $green-color4;
    }

    &:nth-child(3) .nav-link.active {
      background-color: $siren-color;
    }
  }
}

.oncall-info-wrapper {
  display: flex;
  padding-left: 2px;
  .oncall-info-icon::before {
    margin-right: 10px;
    font-size: 19px;
    color: #fff;
  }
}


.assign-count {
  width: 24px;
  height: 24px;
  font-family: $roboto-medium;
  font-size: pxToRem(14px);
  padding: 4px 8px;
  margin-right: 10px;
  text-align: center;
  border-radius: 50%;
  background-color: $light-blue-color ;
  color: $white-color;
}

.scheduler-toast-message.toast-container-new {
  position: fixed;
  bottom: -40px;
  left: 50%;
  min-height: 145px;
  width: 500px;
  transform: translate(-50%, -50%);
  z-index: 100001;

  .toast-wrapper .close-icon::before {
    color: $white-color;
  }
}

.scheduler-wrapper,
.ml-weekday-wrapper {

  .sch-schedule-dropdown,
  .sch-schedule-select__control,
  .courier-m-search .mapboxgl-ctrl-geocoder {
    width: 100%;
    border-color: rgba(18, 18, 18, 0.2);
    font-size: 0.875rem;
    color: rgba(18, 18, 18, 0.87) !important;
    border-radius: 4px;
    font-family: $roboto-regular;
    box-shadow: none;

    .sch-schedule-select__single-value,
    .sch-schedule-select__input-container {
      color: rgba(18, 18, 18, 0.87);
      font-size: pxToRem(14px);
    }

    .sch-schedule-select__control--is-focused {
      .sch-schedule-select__dropdown-indicator::before {
        transform: rotate(180deg);
        transition: transform 300ms ease;
        color: $light-blue-color;
      }
    }

    .sch-schedule-select__indicator-separator,
    .sch-schedule-select__indicator svg {
      display: none;
    }

    .sch-schedule-select__dropdown-indicator {
      @include icomoon("arrow-down", 5px);
    }

    .sch-schedule-select__clear-indicator {
      @include icomoon("close", 8px);

      &::before {
        color: $grey-color;
      }
    }

    .sch-schedule-select__menu {
      z-index: 300;
    }

    .sch-schedule-select__multi-value {
      background-color: transparent;

      .sch-schedule-select__multi-value__label {
        padding-left: 0;
        font-size: 0.875rem;
        color: $grey-color17;
      }

      .sch-schedule-select__multi-value__remove {
        svg {
          color: $grey-color;
        }

        &:hover {
          background-color: inherit;
          color: inherit;
        }
      }
    }

    .sch-schedule-select__option {
      color: $dark-grey-color;
      font-size: pxToRem(14px);
      letter-spacing: 0.25px;

      &.sch-schedule-select__option--is-focused {
        background-color: transparent;
      }

      &.sch-schedule-select__option--is-selected,
      &:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.08);
      }

      &.sch-schedule-select__option--is-disabled {
        opacity: 0.5;

        &:hover {
          cursor: default;
          background-color: $white-color !important;
        }
      }
    }

    .sch-schedule-select__control--is-disabled {
      background-color: $grey-color9;
    }
  }

  position: relative;
  display: flex;
  //justify-content: space-between;
  height: calc(100% - 60px);

  &.popover-wrapper {
    height: auto;
    display: block;

    .ad-loader {
      margin-top: 10px;
    }

    .popover-message {
      text-align: center;
      display: block;
    }

    .service-icon {
      padding-right: 20px;
    }

    .en-popover-title {
      margin-bottom: 12px;
      font-size: pxToRem(22px);
      letter-spacing: 0.39px;
      color: #004E5D;
    }

    .en-popover-uname {
      margin-bottom: 20px;
      font-size: pxToRem(15px);
      text-transform: capitalize;
      color: #1B1B1D;
    }

    &.en-route-popover {
      .dropoff-schedule-wrapper {
        margin: 0;
      }

      .dropoff-time-wrapper {
        max-width: 80% !important;
      }

      .checkin-btn-wrapper {
        margin-top: 25px;
      }

      .accordion-body {
        max-height: 180px;
      }
    }
  }

  .sch-toast-wrapper {
    max-width: fit-content;
    left: 50%;
    top: 35px;
    bottom: unset;
    transform: translate(-50%, -50%);
    padding: 20px 40px;
    transition: none;
    animation: none;

    .toast-info-details {
      flex: 0 0 auto;
      max-width: none;
    }
  }

  .sch-right-toast-wrapper {
    margin-left: 150px;
    font-size: pxToRem(14px);
    font-family: $roboto-regular;
  }

  .sch-left-toast-wrapper {
    left: 0;
    transform: none;
    width: auto;
    max-width: 284px;
  }

  .scheduler-backlogs {
    flex: 0 0 294px;
    max-width: 294px;

    .no-routine-alert {
      font-size: pxToRem(14px);
      font-family: $roboto-regular;
      border-radius: 10px;
      background: $white-color;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border: none;
      line-height: 16px;
      color: $dark-blue-color;
      padding: 20px;
      text-align: center;
    }
  }

  .sch-info-text {
    font-size: pxToRem(14px);
    font-family: $roboto-regular;
  }

  .scheduler-grid {
    //flex-basis: 74%;
    //flex-basis: 100%;
    width: 100%;
    max-width: 100%;
    min-height: 300px;
    margin-left: 5px;
    border: 1px solid $grey-color7;
    overflow-x: auto;

    .service-zone {
      overflow: unset;
    }
  }

  .scheduler-table-wrapper {
    height: 100%;
    overflow-y: auto;
  }

  .scheduler-table {
    height: 100%;
    // width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    thead {
      th {
        position: sticky;
        top: 0;
        max-width: 328px;
        min-width: 328px;
        background-color: $white-color;
        border-bottom: 1px solid $grey-color7;
        border-right: 1px solid $grey-color7;
        z-index: 10;
        // &:not(:last-child) {
        //   border-right: 1px solid $grey-color7;
        // }
      }

      .grid-head {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        // border-bottom: 1px solid $grey-color7;
        height: 100%;
        min-height: 50px;

        // .sch-avatar-wrapper {
        //     // width: 30px !important;
        //     // height: 30px !important;
        //     // margin-right: 12px;
        //     span {
        //         font-family: $roboto-bold;
        //         font-size: 14px;
        //         letter-spacing: 0.56px;
        //         line-height: 24px;
        //     }
        // }
        .sch-user-name {
          letter-spacing: 0.14px;
          line-height: 22px;
        }

        .sch-user-name,
        .sch-user-position {
          font-weight: 400;
          color: rgba(0, 0, 0, 0.87);
          text-transform: capitalize;
        }

        .sch-user-position {
          letter-spacing: 0.39px;
          font-size: pxToRem(12px);
          line-height: 16px;
        }

        .sch-th-left-wrapper {
          flex: 0 0 90%;
          max-width: 90%;
        }

        .enroute-start::before {
          color: #6D66DC;
        }

        .enroute-home:hover,
        .enroute-icon:hover {
          cursor: pointer;
        }
      }

      .service-icon {
        @include icomoon("routine-draw", 27px);
      }

      .user-name {
        font-family: $roboto-regular;
        font-size: pxToRem(16px);
        letter-spacing: 0.144px;

        .user-position {
          display: block;
          font-size: pxToRem(12px);
          letter-spacing: 0.396px;
        }
      }
    }

    tbody {
      td {
        position: relative;
        padding: 10px 5px;
        border-right: 1px solid $grey-color7;
        vertical-align: top;

        // &:not(:last-child) {
        //   border-right: 1px solid $grey-color7;
        // }
        .facility-td-wrapper {
          // height: 100%;
          //overflow-y: auto;
          //position: absolute;
          //top: 0;
        }
      }

      .service-type {
        margin-bottom: 15px;
      }
    }

    .user-list {
      display: flex;

      .user {
        overflow: scroll;
        border: 1px solid $grey-color7;

        h4 {
          border-bottom: 1px solid $grey-color7;
        }
      }
    }
  }

  .service-zone {
    position: relative;
    overflow: auto;
    height: calc(100% - 52px);

    &.sch-backlog-bodycontainer {
      background-color: $white-color4;
    }
  }

  //Service Types
  .service-type {
    display: flex;
    align-items: center;
    padding: 10px;
    min-height: 52px;
    text-align: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: $white-color;

    .title {
      font-family: $roboto-medium;
      font-size: pxToRem(16px);
      padding-left: 10px;
      letter-spacing: 0.144px;
    }

    //Routine Draws
    &.routine-draws {
      background-color: $green-color3;

      .service-icon {
        @include icomoon("routine-draw", 27px);
      }
    }

    //Home Health
    &.home-health {
      background-color: $siren-color;

      .service-icon {
        @include icomoon("home-health", 27px);
      }
    }

    //On Call
    &.on-call {
      background-color: $green-color4;

      .service-icon {
        @include icomoon("on-call", 27px);
      }
    }

    //Drop Off
    &.drop-off {
      background-color: $purple-color;

      .service-icon {
        @include icomoon("dropoff-new", 32px);

        &::before {
          color: $white-color;
        }
      }
    }
  }

  //Region/Territory/Zone
  .service-rtz {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 8px;
    border-bottom: 1px solid rgba(34, 80, 90, 0.1);

    .title {
      font-family: $roboto-medium;
      font-size: pxToRem(20px);
      letter-spacing: 0.15px;
      text-transform: uppercase;
      color: $swamp-color;
    }
  }

  //Sub Service Type
  .sub-serivce-type {
    display: flex;
    align-items: center;
    padding: 10px;

    .service-icon {
      @include icomoon("care-facility", 22px);

      &::before {
        color: $cyprus-color;
      }
    }

    .icon-add-user,
    .icon-non_service {
      &::before {
        font-size: 21px;
        color: $cyprus-color;
      }
    }

    .title {
      font-family: $roboto-medium;
      font-size: pxToRem(12px);
      color: rgba(0, 54, 65, 0.87);
      padding-left: 10px;
      letter-spacing: 0.084px;
    }
  }

  //Service Locations
  .service-locations {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    margin: 8px 0;
    height: 44px;
    border-radius: 8px;
    border: 1px solid;

    &:first-child {
      margin-top: 0;
    }

    //&:last-of-type { margin-bottom: 0; }
    &:hover {
      cursor: pointer;
    }

    .sch-avatar-wrapper {
      span {
        font-size: pxToRem(14px);
        line-height: unset;
      }
    }

    .title {
      flex: 1 1 75%;
      max-width: 75%;
      display: flex;
      align-items: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-family: $roboto-regular;
      font-size: pxToRem(14px);
      color: $white-color;
      letter-spacing: 0.252px;
      text-transform: capitalize;

      .popover-dropoff-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .ah-sch-account-name {
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .service-icon {
      flex: 1 1 auto;
      text-align: right;
    }

    //Active State
    &.active,
    &.active-2X {
      background-color: $orient-color;
      border-color: $deepsea-green-color;

      .service-icon {
        @include icomoon("drag", 16px);
        padding-top: 4px;

        &::before {
          color: rgba(246, 253, 255, 0.78);
        }
      }

      &:hover {
        background-color: $green-color6;
      }

      &:focus {
        background-color: $green-color7;
      }
    }

    //Assigned State
    &.assigned {
      background-color: $seafoam-color;
      border-color: $deepsea-green-color;
      padding: 5px 10px;
      cursor: default;

      .title {
        font-family: $roboto-regular;
        color: $cyprus-color;
      }

      .service-icon {
        @include icomoon("scheduled-already", 22px);

        &::before {
          color: $green-color3;
        }
      }
    }

    //Checked In State
    &.checked-in {
      background-color: $seafoam-color;
      border-color: $deepsea-green-color;
      padding: 5px 10px;

      .service-icon {
        @include icomoon("check-in", 20px);

        &::before {
          color: $green-color3;
        }
      }

      .title {
        color: $cyprus-color;
      }

      &:hover {
        cursor: default;
      }

      &:focus {
        background-color: $blue-color13;
      }
    }

    //Inactive State
    &.inactive {
      background-color: $white-color5;
      border-color: transparent;
      padding: 5px 10px;

      .title {
        color: $swamp-color;
      }

      .service-icon {
        @include icomoon("drag", 16px);
        padding-top: 4px;

        &::before {
          color: rgba(0, 54, 65, 0.78);
        }
      }

      &:hover {
        background-color: $white-color6;
      }

      &:focus {
        background-color: $white-color7;
      }
    }

    //Inactive-Assigned State
    &.inactive-assigned {
      background-color: rgba(131, 167, 173, 0.27);
      padding: 5px 10px;

      .title {
        color: $green-color3;
      }

      .service-icon {
        @include icomoon("scheduled-already", 20px);
      }

      &:hover {
        cursor: default;
        background-color: rgba(131, 167, 173, 0.27);
      }

      &:focus {
        background-color: #c2d1d4;
      }
    }

    //Visit Complete State
    &.visit-complete {
      background-color: $green-color2;
      border-color: $green-color;
      padding: 5px 10px;

      .title {
        color: $label-color;
      }

      .service-icon {
        @include icomoon("check-out", 20px);

        &::before {
          color: $green-color;
        }
      }

      &:hover {
        background-color: $green-color2;
        cursor: default;
      }
    }

    .delete-icon {
      @include icomoon("close", 12px);

      &::before {
        color: $green-color3 !important;
        padding-right: 10px;
      }
    }

    .drag-icon {
      @include icomoon("drag", 16px);
      padding-top: 4px;

      &::before {
        color: rgba(246, 253, 255, 0.78);
      }
    }

    //Default Assigned On Calender State
    &.default-assigned-calender {
      background-color: rgba(131, 167, 173, 0.27);
      border-color: $grey-color5;

      .service-icon2 {
        @include icomoon("close", 12px);

        &::before {
          color: $green-color3;
          padding-right: 10px;
        }
      }

      .title {
        color: $green-color3;
      }

      .service-icon {
        @include icomoon("drag", 16px);
        padding-top: 4px;

        &::before {
          color: rgba(0, 54, 65, 0.65);
        }
      }

      &:hover {
        background-color: $grey-color13;
      }
    }

    //Assigned On Calender State
    &.assigned-on-calender {
      background-color: $seafoam-color;
      color: $deepsea-green-color;

      .service-icon2 {
        @include icomoon("close", 12px);

        &::before {
          color: $green-color3;
          padding-right: 10px;
        }
      }

      .title {
        color: $cyprus-color;
      }

      .service-icon {
        @include icomoon("drag", 16px);
        padding-top: 4px;

        &::before {
          color: rgba(0, 78, 93, 0.73);
        }
      }

      &:hover {
        background-color: $blue-color13;
      }

      &:focus {
        background-color: $blue-color14;
      }
    }

    //Checked In Calender State
    &.checked-in-calender {
      background-color: $seafoam-color;
      border-color: $deepsea-green-color;

      .service-icon {
        @include icomoon("check-in", 20px);

        &::before {
          color: $green-color3;
        }
      }

      .title {
        color: $cyprus-color;
      }

      &:hover {
        cursor: default;
        //background-color: $blue-color12;
      }

      &:focus {
        background-color: $blue-color13;
      }
    }

    //Inactive Assigned Calender State
    &.inactive-assigned-calender {
      background-color: rgba(131, 167, 173, 0.27);

      .service-icon2 {
        @include icomoon("close", 12px);

        &::before {
          color: $green-color3;
          padding-right: 10px;
        }
      }

      .title {
        color: $green-color3;
      }

      .service-icon {
        @include icomoon("drag", 16px);
        padding-top: 4px;

        &::before {
          color: rgba(0, 54, 65, 0.78);
        }
      }

      &:hover {
        background-color: $grey-color13;
      }
    }

    //Complete Calender State
    &.visit-calender-complete {
      background-color: $green-color2;
      border-color: $green-color;

      .title {
        color: $label-color;
      }

      .service-icon {
        @include icomoon("check-out", 20px);

        &::before {
          color: $green-color;
        }
      }

      &:hover {
        //background-color: $green-color5;
        cursor: default;
      }
    }

    //2x Facility Chips

    //Assigned-1X
    &.assigned-1X {
      background-color: $seafoam-color;
      border-color: transparent;

      .title {
        // display: flex;
        // align-items: center;
        color: $cyprus-color;
        // @include icomoon("2x-user", 20px);
        // &::before {
        //   padding-right: 10px;
        //   color: $cyprus-color;
        // }
      }

      .service-icon {
        @include icomoon("drag", 16px);
        padding-top: 4px;

        &::before {
          color: $cyprus-color;
        }
      }
    }

    //Assigned-2X Default
    &.assigned-2X-default {
      background-color: $seafoam-color;
      border-color: $deepsea-green-color;

      .title {
        // display: flex;
        // align-items: center;
        color: $cyprus-color;
        // @include icomoon("2x-user", 20px);
        // &::before {
        //   color: $cyprus-color;
        //   padding-right: 10px;
        // }
      }

      &:hover {
        cursor: default;
      }
    }

    //Assigned 2X Combo
    &.assigned-2X-combo {
      background-color: $seafoam-color;
      border-color: $deepsea-green-color;

      .title {
        // display: flex;
        // align-items: center;
        color: $cyprus-color;
        // @include icomoon("2x-user", 20px);
        // &::before {
        //   color: $cyprus-color;
        //   padding-right: 10px;
        // }
      }

      .service-icon.checked-out {
        @include icomoon("check-out", 20px);

        &::before {
          color: $green-color;
        }
      }

      .service-icon.checked-in {
        @include icomoon("check-in", 20px);

        &::before {
          color: $green-color3;
        }
      }

      .service-icon.drag {
        @include icomoon("drag", 20px);

        &::before {
          color: $cyprus-color;
        }
      }

      .states-combo {
        display: flex;
        justify-content: flex-end;
        flex: 1 1 auto;
        padding-right: 10px;

        .icon:last-child {
          padding-left: 20px;
        }

        &.pr-0 {
          padding-right: 0;
        }
      }

      .service-icon {
        position: relative;
        @include icomoon("check-out", 20px);

        &::before {
          color: $green-color;
        }
      }

      .service-icon2 {
        position: relative;
        @include icomoon("check-in", 20px);

        &::before {
          color: $green-color3;
        }
      }

      .user-count {
        position: absolute;
        top: -8px;
        right: -10px;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        padding-top: 3px;
        background: $red-color;
        text-align: center;
        font-family: $roboto-medium;
        font-size: 0.6875rem;
        color: $white-color;
        z-index: 8;
      }

      &.visit-complete {
        background-color: $green-color2;
        border-color: $green-color;

        .title {
          color: $label-color;

          &::before {
            color: $label-color;
          }
        }
      }

      &:hover {
        cursor: default;
      }
    }

    &.surge-facility-default,
    &.checked-in-surge {
      background-color: #FFEBEB;
      color: $siren-color;
      border-color: $siren-color ;

      .service-icon2 {
        @include icomoon("close", 12px);

        &::before {
          color: $siren-color;
          padding-right: 10px;
        }
      }

      .title {
        color: $siren-color;
      }

      // &:hover {
      //   background-color: $blue-color13;
      // }
      // &:focus {
      //   background-color: $blue-color14;
      // }
    }

    &.checked-out-surge {
      background-color: $red-color3;
      border-color: $red-color3;

      .service-icon2 {
        @include icomoon("close", 12px);

        &::before {
          color: $white-color;
          padding-right: 10px;
        }
      }

      .title {
        color: $white-color;
      }
    }


    &.checked-out-nonservice {
      background-color: $light-blue-color1;
      border-color: $light-blue-color1;

      .service-icon2 {
        @include icomoon("close", 12px);

        &::before {
          color: $white-color;
          padding-right: 10px;
        }
      }

      .title {
        color: #FFEBEB;
      }

    }

    &.checked-in-nonservice {
      .service-icon {
        @include icomoon("check-in", 20px);

        &::before {
          color: $dark-blue-color1;
        }
      }
    }

    &.checked-in-surge {
      .service-icon {
        @include icomoon("check-in", 20px);

        &::before {
          color: $siren-color;
        }
      }
    }

    &.checked-out-surge {
      .service-icon {
        @include icomoon("check-out", 20px);

        &::before {
          color: $white-color;
        }
      }
    }

    &.checked-out-nonservice {
      .service-icon {
        @include icomoon("check-out", 20px);

        &::before {
          color: $white-color;
        }
      }

      .title {
        color: $white-color;
      }
    }

    &.nonservice-facility-default,
    &.checked-in-nonservice {
      background-color: #DFDFF8;
      color: $dark-blue-color1;

      .service-icon2 {
        @include icomoon("close", 12px);

        &::before {
          color: $dark-blue-color1;
          padding-right: 10px;
        }
      }

      .title {
        color: $dark-blue-color1;
      }

      // &:hover {
      //   background-color: $blue-color13;
      // }
      // &:focus {
      //   background-color: $blue-color14;
      // }
    }

    &.drop-off-service {
      background-color: $purple-color2;
      border: none;

      &:first-of-type {
        margin-top: 8px;
      }

      .title {
        display: flex;
        align-items: center;
        color: $purple-color;

        span {
          padding-right: 10px;
        }
      }

      &.courier .service-icon {
        @include icomoon("Courier", 20px);
      }

      &.labname .service-icon {
        @include icomoon("flask", 20px);
      }

      &.hospital .service-icon {
        @include icomoon("Hospital-agenda", 20px);
      }

      .service-icon.icon {
        &::before {
          color: $purple-color;
        }
      }
    }

    &.sc-border-red {
      border-width: 2px;
      border-color: $red-color;

      &:hover {
        background-color: $blue-color13 ;
        cursor: pointer;
      }
    }

    &.assigned-1X,
    &.assigned-2X-default,
    &.assigned-2X-combo {
      .bg-user-icon {
        @include icomoon('2x-user', 20px);

        &::before {
          padding-right: 10px;
          color: $cyprus-color;
        }
      }
    }

    //Inactive-Facility State
    &.inactive-facility {
      background-color: rgba(131, 167, 173, 0.27) !important;
      border-color: transparent !important;
      padding: 5px 10px;

      .title {
        color: $green-color3 !important;
      }

      .service-icon {
        // @include icomoon("drag", 16px);
        // padding-top: 4px;

        &::before {
          color: rgba(0, 54, 65, 0.78);
        }
      }

      // &:hover {
      //   cursor: default;
      //   background-color: rgba(131, 167, 173, 0.27);
      // }

      // &:focus {
      //   background-color: #c2d1d4;
      // }
    }
  }

  .oncall-assigned-state {
    background-color: $blue-color20;
    border-color: $green-color4;

    .title span {
      color: $cyprus-color;
    }

    .drag-icon:before {
      color: rgba(0, 78, 93, 0.73);
    }
  }


  .oncall-unassigned-state {
    background-color: $green-color4;
    border-color: $green-color4;

    .title span {
      color: $white-color;
    }
  }

  //Add Schedule Accordion

  .schedule-accordian-wrapper.accordion {
    .accordion-item {
      position: relative;
      width: 328px;
      border: none;
      margin-bottom: 10px;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .accordion-button,
    .accordion-button.collapsed {
      display: flex;
      align-items: center;
      height: 52px;
      padding: 7px 12px;
      background-color: $grey-color9;
      border-radius: 8px;
      box-shadow: none;

      &::after {
        display: none;
      }

      &:focus {
        border: none;
        box-shadow: none;
      }

      &:hover {
        cursor: pointer;
      }

      .accordian-head {
        p {
          font-size: pxToRem(14px);
          color: $grey-color5;
          font-family: $roboto-medium;
        }

        .title {
          padding-top: 5px;
          font-size: pxToRem(10px);
          text-transform: uppercase;
          line-height: 11px;
          letter-spacing: 1.5px;
        }

        .sub-title {
          font-size: pxToRem(16px);
        }
      }
    }

    .accordion-body {
      padding: 0;

  
    }

    .accordion-button[aria-expanded="true"] {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      p {
        color: $white-color;
      }
    }

    .routine-draws+.accordion-collapse .accordion-body {
      background-color: $white-color4;
    }

    .home-health+.accordion-collapse .accordion-body {
      background-color: $pink-color2;
    }

    .on-call+.accordion-collapse .accordion-body {
      background-color: $green-color8;
    }

    .drop-off+.accordion-collapse .accordion-body {
      background-color: $purple-color4;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .schedule-options {
      position: absolute;
      top: 17px;
      right: 16px;
      z-index: 9;

      span {
        cursor: pointer;
      }

      span:not(:last-child) {
        padding-right: 15px;
      }
    }

    .schedule-delete {
      &.disable {
        @include icomoon("delete-disabled", 16px);
      }

      &.active {
        @include icomoon("delete", 16px);
      }

      &::before {
        color: $white-color;
      }
    }

    .schedule-add-new {
      @include icomoon("plus", 16px);

      &::before {
        color: $white-color;
      }
    }

    .schedule-remove {
      @include icomoon("hyphen-dark", 16px);

      &:hover {
        cursor: pointer;
      }

      &::before {
        color: $white-color;
      }
    }

    .sch-close-wrapper {
      padding: 10px 15px 0;
      text-align: right;
    }

    .schedule-edit {
      @include icomoon("edit", 16px);

      &::before {
        color: $white-color;
      }
    }

    .schedule-edit,
    .schedule-delete {
      &:hover {
        opacity: .8;
      }
    }

    .accordion-header {
      min-height: 52px;

      &.routine-draws button {
        .service-icon {
          @include icomoon("routine-draw", 30px);

          &::before {
            color: rgba(3, 54, 65, 0.4);
            padding-right: 10px;
          }
        }

        &[aria-expanded="true"] {
          background-color: $green-color3;
        }
      }

      &.home-health button {
        .service-icon {
          @include icomoon("home-health", 30px);

          &::before {
            color: rgba(142, 7, 70, 0.38);
            padding-right: 10px;
          }
        }

        &[aria-expanded="true"] {
          background-color: $siren-color;
        }
      }

      &.on-call button {
        .service-icon {
          @include icomoon("on-call", 30px);

          &::before {
            color: rgba(11, 107, 87, 0.29);
            padding-right: 10px;
          }
        }

        &[aria-expanded="true"] {
          background-color: $green-color4;
        }
      }

      &.drop-off button {
        .service-icon {
          @include icomoon("dropoff-new", 34px);

          &::before {
            padding-right: 10px;
            color: rgba(30, 61, 113, 0.65);
          }
        }

        &[aria-expanded="true"] {
          background-color: $purple-color;
        }
      }

      button[aria-expanded="true"] .service-icon::before {
        color: $white-color;
      }
    }

    .choose-time-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 5px 15px 0;

      .seperator {
        display: flex;
        align-items: center;
        margin-top: 20px;

        .service-icon {
          @include icomoon("hyphen-dark", 15px);

          &::before {
            color: $black-color2;
          }
        }
      }


    }

    .choose-shift-wrapper {
      padding: 5px 15px 0;
    }

    .sch-choosetime-description {
      padding: 5px 10px;

      label {
        width: 100%;
        font-family: $roboto-regular;
        color: $grey-color4;
      }
    }

    label {
      margin: 0;
      font-family: $roboto-regular;
      font-size: pxToRem(12px);
      letter-spacing: 0.4px;
      color: $grey-color4;
    }

    .schedule-start-time,
    .schedule-end-time {
      flex-basis: 42%;
    }

    .scheduler-button-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      padding: 5px 10px;

      .btn {
        width: auto;
        height: 40px;
        border-radius: 8px;
        font-family: $roboto-regular;
        font-size: pxToRem(14px);
        letter-spacing: 1px;
        text-align: center;

        &:not(:last-child) {
          margin-right: 15px;
        }

        &.btn-cancel {
          color: $blue-color2;
          border: 1px solid $blue-color2;
        }

        &.btn-add {
          color: $white-color;
          background-color: $dark-blue-color;

          &:hover {
            background-color: $blue-color2;
          }
        }

        &.disabled {
          background-color: $grey-color9;
          color: $grey-color5;
          border: none;
          cursor: default;
        }
      }
    }
    .droppable-area-border {      
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%230F5C6BFF' stroke-width='3' stroke-dasharray='15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 8px;
      margin-top: 5px;
      > div {
        padding: 10px;
      }
      &.droppable-area-inactive > div {
        padding: 0px;
      }
    }
    .droppable-area-active {
      background-color: rgba(4, 160, 131,.07);
    }
    .droppable-area-inactive {
      background-color: #F2F2F2;
    }
    .drop-facility-zone-text {
      width: 200px;
      padding: 15px 0;
      margin: auto;
      letter-spacing: 0.01em;
      color: rgba(110, 110, 110, 0.6);
      // color: rgba(0, 0, 0, 0.55);
      font-size: pxToRem(12px);
      font-family: $roboto-regular;
      // letter-spacing: 0.4px;
      line-height: 16px;
      text-align: center;
      // word-break: break-all;
    }
    .error-message {
      label,
      .error-message-text {
        color: $red-color;
      }
      .sch-schedule-select__control{
        border-color: $red-color;
      }
      .error-message-text {
        font-family: $roboto-regular;
        font-size: pxToRem(12px);
        line-height: 16px;
        letter-spacing: 0.33px;
        padding-top: 5px;
        color: $red-color;
      }
    }
  }

  .sch-avatar-wrapper {
    width: 30px !important;
    height: 30px !important;
    margin-right: 10px;

    span {
      font-family: $roboto-bold;
      font-size: pxToRem(14px);
      letter-spacing: 0.56px;
      line-height: unset;
    }
  }
}

.sc-copycal-modal {
  &.hh-view-attachment-modal { 
    height: 100%;
    .modal-body {
      height: calc(100% - 95px);
      padding-bottom: 5px; 
    }
    .modal-content { height: 100%; }
    .hh-view-attachment-modal-body {
      padding-bottom: 0;
    }
  }
  &.hh-view-attachment-modal .modal-dialog { height: 350px;}
  .modal-body,
  .modal-content {
    flex: 0 0 auto;
    height: auto;
  }

  .modal-content {
    padding: 15px 15px 0;
  }

  .modal-body {
    padding: 20px 30px 25px;
    &.attachment-div { height: 100%; display: grid;}
  }

  .modal-dialog {
    max-width: 584px;
    height: 260px;
    margin-top: 140px;

    p.sch-error-message-alert {
      position: static;
      width: 100%;
      margin: 20px 0 0 25px;
      text-align: left;
    }

    .scheduler-filters {
      .sch-copycal-dropdown {
        margin-top: 2px;
      }

      .sch-copycal-select__control {
        width: 225px;
        //height: 40px;
        background: transparent;
        border: none;
        color: $black-color;
        letter-spacing: 0.14px;

        &::after {
          display: none;
        }

        .sch-copycal-select__multi-value {
          margin: 0;
          background-color: transparent;
        }

        .sch-copycal-select__indicator-separator {
          display: none;
        }

        .sch-copycal-select__dropdown-indicator {
          @include icomoon("arrow-down", 6px);

          svg {
            display: none;
          }
        }

        &.sch-copycal-select__control--menu-is-open {
          .sch-copycal-select__dropdown-indicator::before {
            transform: rotate(180deg);
            transition: transform 300ms ease;
            color: $light-blue-color;
          }
        }

        .sch-copycal-select__value-container--is-multi {
          input {
            position: absolute;
          }
        }

        .sch-copycal-select__clear-indicator,
        .sch-copycal-select__multi-value__remove {
          @include icomoon("close", 11px);

          svg {
            display: none;
          }
        }

        .sch-copycal-select__clear-indicator {
          padding: 3px 3px 0;
        }

        .sch-copycal-select__multi-value__remove {
          &:hover {
            background-color: transparent;
          }

          &::before {
            font-size: 8px;
          }
        }

        .sch-copycal-select__multi-value__label {
          font-size: pxToRem(14px);
          color: $label-color ;
        }

        .copycal-two-more {
          padding-left: 8px;
          color: #6c757d;
        }

        .sch-copycal-select__placeholder {
          font-size: pxToRem(14px);
          letter-spacing: 0.25px;
          font-family: $roboto-regular;
          line-height: 21px;
          color: $grey-color17;
        }
      }

      .sch-copycal-select__menu {
        max-height: 120px;
        border-radius: 8px;
        border: none;
        background-color: $white-color;
        z-index: 11;

        .sch-copycal-select__option {
          display: flex;
          width: 100%;
          align-items: center;
          margin-bottom: 0;
          background-color: $white-color;

          label {
            padding: 0;
            font-size: pxToRem(14px);
            letter-spacing: 0.25px;
            text-transform: capitalize;
            font-family: $roboto-regular;
            line-height: 16px;
            color: $dark-grey-color;
            &:hover {
              cursor: pointer;
            }
          }

          input {
            width: 18px;
            height: 18px;
            margin-right: 10px;
            margin-top: 0;
            border-color: rgba(0, 0, 0, .6);
            border-width: 3px;

            &.form-check-input:checked[type=checkbox] {
              background-color: $dark-blue-color;
              background-position: -1px;
            }
          }

          &.sch-copycal-select__option--is-selected {
            color: $dark-blue-color;
          }

          &:hover {
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.08);
          }

          &.sch-copycal-select__option--is-disabled {
            opacity: .5;

            &:hover,
            label:hover {
              cursor: default;
              background-color: transparent;
            }
          }
        }

        .css-4ljt47-MenuList,
        .sch-copycal-select__menu-list--is-multi {
          max-height: 120px;
        }

      }
    }
  }

  .primary-btn.ad-progress-btn {
    font-family: $roboto-bold;

    &:disabled,
    &:disabled:hover {
      background-color: $light-grey-color;
      color: $label-color;
    }
  }

  .sc-week-title {
    letter-spacing: 0.098px;
    color: $black-color2;
  }

  .copy-wrapper {
    color: rgba(0, 0, 0, .5);

    .copy-calendar {
      //padding-bottom: 5px;
      line-height: 1;

      &::before {
        color: rgba(0, 0, 0, .5);
      }
    }
  }

  .sch-confirmation-wrapper {
    background-color: rgba(127, 121, 224, .20);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin: auto;

    .confirmation-icon {
      padding-left: 4px;
    }

    &.sch-success-wrapper {
      width: 76px;
      height: 76px;
      background-color: $green-color2 ;
    }

  }

  .confirmation-title,
  .confirmation-subtitle {
    //max-width: 260px;
    margin: 20px auto 0;
    color: $grey-color6 ;
    line-height: 19px;
  }

  .success-title {
    //max-width: 260px;
    margin: 40px auto 20px;
    text-align: center;
    font-size: pxToRem(22px);
    color: $blue-color11;
    line-height: 19px;
  }
  // .confirmation-title {
  //   max-width: 330px;
  // }
  .edit-inactive-user {
    display: flex;
    justify-content: center;
  }

  .list-user-names {
    max-height: 130px;
    padding-right: 20px;
    overflow-y: auto;
    text-align: left;

    li {
      margin-bottom: 3px;
      font-family: $roboto-regular;
      font-size: pxToRem(16px);
      color: $red-color3;
    }
  }
  &.user-status-modal-wrapper {
    .modal-dialog {
      margin-top: 60px;
      height: 590px;
    }
    .modal-content {
      // height: 100%;
      max-height: 590px;
    }
    .modal-body {
      height: auto;
      flex: 1;
    }
    .sch-modal-body-bottom-content {
      max-height: 350px;
      padding: 0 30px;
      overflow-y: auto;
    }
    .edit-inactive-user {
      // height: calc(100% - 168px);
      // margin-top: 0 !important;
      
      ul {
       
        // max-height: 500px;
       
        // li { margin-bottom: 5px;}
      }
    }
  }
}

th.on-call-header {
  background-color: $red-color3 !important;
  z-index: 51 !important;
}
td.on-call-header {
  background: #fff !important;
  z-index: 50 !important;
}
.on-call-header {
  position: sticky !important;
  left: 0;
}
.no-coverage-wrapper h2 {
  color: #fff !important;
}
.oncall-count-popover-container .popoverclose {
  right: 15px !important;
}
.sch-popover-container,
.oncall-count-popover-container {
  z-index: 99;

  .popover-arrow {
    //box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    border-left-width: 15px;
    border-top-width: 15px;
    z-index: 95;
  }
}

.popover-arrow-container {
  .schedule-accordian-wrapper{
    max-height: 405px;
    overflow: auto;
    padding: 0 3px 3px;
  }
}
.sch-popover {
  min-width: 662px;
  max-width: 450px;
  padding: 30px 25px 25px;
  z-index: 95;
  background-color: $white-color;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  //filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  border: none;
  border-radius: 4px;
  &.checkin-popover {
    max-width: 650px;
    .schedule-time { flex: 0 0 27%;}
  }

  @include breakpoint(max1024) {
    min-width: 495px;
  }
  &.sch-countlist-popover {
    min-width: 250px;
    max-width: 300px;
    max-height: 500px;
    overflow: auto;
    padding: 38px 5px 20px 15px;
    .count-userlist-wrapper {
      // max-height: 130px;
      // overflow-y: auto;
      margin: 10px 12px 0 0;
    }
    .sch-th-left-wrapper {
      padding: 6px 10px 6px 0;
    }
    .sch-user-wrapper {
      padding-left: 10px;
      text-transform: capitalize;
    }
    .sch-no-coverage-title {
      font-family: $roboto-bold;
      font-size: 18px;
      letter-spacing: 0.0028px;
      &.color-green {
        color: #136B57;
      }
      &.color-red {
        color: $red-color3
      }
      &.color-yellow {
        color: #DB8016;
      }
    }
    .sch-noc-desc {
      padding-top: 4px;
      line-height: 16px;
      letter-spacing: 0.00132px;
      color: $grey-color4;
    }
    .sch-noc-left-wrapper {
      padding: 5px 0 0 30px;
      span {
        color: #000000;
      }
    }
  }
  &.sch-dropoff-popover {
    min-width: 495px;

    .mapboxgl-ctrl-geocoder {
      max-width: none;
    }
  }

  .checkin-btn-wrapper {
    .primary-btn-outline {
      min-width: 72px;
      background-color: $dark-blue-color;

      &:hover {
        background-color: $blue-color2
      }

      &:disabled {
        border: none;
        color: $label-color !important;
        background-color: $light-grey-color;
      }
    }

    .primary-btn-outline.outline {
      color: $dark-blue-color;
      border-color: $dark-blue-color;

      &:hover {
        background-color: transparent;
        color: $blue-color2;
        border-color: $blue-color2;
      }
    }
  }

  .choose-time-wrapper {

    // max-width: 180px;
    //margin-bottom: 25px;
    label {
      // min-height: 32px;
      line-height: 1rem;
      // margin-bottom: 8px !important;
    }
  }

  .choose-shift-wrapper {

    label {
      min-height: 32px;
      line-height: 1rem;
    }
  }

  .schedule-time {
    position: relative;
  }

  .sch-notes-wrapper {
    padding: 5px 15px 12px;

    .accordion-body {
      // max-height: 162px;
      overflow-y: auto;

      @include breakpoint(min1920) {
        max-height: 400px;
      }

      .al-activity {
        display: block;
      }
    }

    .audit-list-wrapper li {
      flex: 0 0 45%;
      margin-bottom: 8px;
      font-family: $roboto-regular;
      font-style: italic;
      font-size: pxToRem(14px);
      color: $grey-color4;
    }

    .accordion-item {
      width: 100% !important;
    }
  }

  .audit-log-header {
    .accordion-button {
      padding: 0 !important;
      text-transform: uppercase;
      font-family: $roboto-regular;
      font-size: pxToRem(18px);
      background-color: transparent !important;
      color: $green-color3;

      &.collapsed {
        .al-arrow-down {
          margin-top: 0;
          transform: rotate(-180deg);
        }
      }
    }

    .al-arrow-down {
      padding: 0 10px;
      margin-top: -6px;
      transition: transform 300ms ease;
      @include icomoon("arrow-down-outlined", 8px);

      &::before {
        color: $green-color3;
      }
    }
  }

  .check-in,
  .check-out {
    &::before {
      color: $black-color4;
    }

    position: absolute;
    top: 9px;
    left: -32px;
  }
  .spinwithservicestop {
    left: -26px;
  }

  .sub-serivce-type {
    align-items: flex-start;
    padding: 0;

    .title {
      padding-left: 0;
      font-size: 22px;
      line-height: 29px;
      color: $green-color3;
      text-transform: capitalize;
    }

    p {
      // padding-left: 20px;
      color: $black-color4;
      letter-spacing: 0.5px;
      line-height: 21px;
    }

    p:last-child {
      padding-bottom: 10px;
    }
  }

  .service-icon:before {
    font-size: 30px !important;
  }

  .schedule-time {
    flex: 0 0 29.5%;

    &.sch-checkout-wrapper {
      margin: 0 15px 0 40px;
    }
  }

  .schedule-accordian-wrapper {   
    &.aha-checkin-accordion-wrapper { 
      padding-left: 35px;
      margin-bottom: 5px;
    }

    &.dropoff-schedule-wrapper {
      max-width: none;

      .choose-time-wrapper {
        max-width: 180px;
        padding: 0;

        .form-check-label {
          margin: 0 !important;
          letter-spacing: 0.252px;
          color: $label-color ;
          line-height: 29px;
        }

        .sch-schedule-dropdown {
          width: 164px;
        }

        &.dropoff-time-wrapper {
          max-width: none;
          display: block;

          .dropoff-lat-long {
            input {
              min-width: auto;
            }
          }
        }
      }

      .drop-off-select {
        .sch-schedule-dropdown {
          width: 100%;
        }
      }

      .cus-dropdown-container {
        position: relative;
        width: 164px;
      }
    }
  }

  .sch-schedule-select__control {
    min-height: 40px;
  }

  .sch-schedule-select__menu-list {
    max-height: 200px;
  }

  .title {
    text-transform: capitalize;
  }

  .service-icon2 {
    @include icomoon("close", 13px);

    &::before {
      color: $grey-color11;
      padding-right: 10px;
    }

    &.popoverclose {
      position: absolute;
      top: 25px;
      right: 25px;
    }

    &:hover {
      cursor: pointer;
    }

    .twox-surgepopover {
      min-width: 335px;
      max-width: 405px;

      .schedule-time {
        flex: 0 0 68%;
      }
    }
  }

  textarea {
    min-height: 50px;
    max-height: 185px;
    width: 100%;
    font-size: pxToRem(14px);
    border-radius: 4px;
    border: 1px solid rgba(18, 18, 18, 0.2);
    padding: 10px;
    letter-spacing: 0.18px;
    color: $label-color !important;
    font-family: $roboto-regular;

    &:disabled {
      background-color: $grey-color9;
      color: $label-color;
    }

    &:active,
    &:focus {
      box-shadow: 0 0 0 4px rgb(187, 218, 236);
      outline: none;
      border-color: $blue-color7;
    }

    &:hover {
      border-color: $grey-color4;
      color: $grey-color4;
    }

    &:disabled:hover {
      border-color: $grey-color7;
    }
  }

  &.x2-facility-popover {
    min-width: auto;
    max-width: none;
    padding: 0 0 25px;
    background-color: transparent;
    filter: none;
    box-shadow: none;
  }
}

.add-dropoff-title {
  line-height: 24px;
  letter-spacing: 0.098px;
  text-transform: capitalize;
  color: $light-blue-color;

  &:hover {
    cursor: pointer;
  }
}

//tooltip
.service-location-tooltip {
  .tooltip-inner {
    font-size: pxToRem(14px);
    background-color: $white-color;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    line-height: 16px;
    color: $label-color;
    text-align: left;
    border-radius: 6px;
    padding: 10px 12px;
    max-width: 250px;
  }

  .tooltip-arrow::before {
    border-top-color: $white-color;
  }

  &.tooltip.show {
    opacity: 1;
  }

  .tooltip-adress-group {
    display: flex;
    flex-direction: column;
  }
}

#tooltip-disabled{
  z-index: 9999999;
}

.ml-weekday-wrapper {
  display: block;
  height: unset;

  .ml-selectdays-wrapper {
    min-height: 75px;

    .schedule-time {
      flex: 0 0 48%;
    }
  }

  .sch-schedule-select__menu-list {
    max-height: 100px;
  }

  .ml-closed-text {
    padding-top: 1px;
  }
}

/* The container */
.ml-weekday {
  label.ml-label-container {
    position: relative;
    display: block;
    max-width: fit-content;
    margin: 0;
    padding: 3px 0 0 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .ml-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 3px solid rgba(0, 0, 0, .6);
    border-radius: 4px;
  }

  input:checked~.ml-checkmark {
    background-color: $dark-blue-color;
    border: none;
  }

  .ml-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked~.ml-checkmark:after {
    display: block;
  }

  .ml-checkmark:after {
    left: 6px;
    top: 3px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.courier-m-search {
  .mapboxgl-ctrl-geocoder {
    min-height: 40px;
    border-width: 1px;
    border-style: solid;

    .mapboxgl-ctrl-geocoder--icon-close {
      margin-top: 5px;
      fill: $grey-color11;
    }

    button:hover {
      background-color: transparent;
    }
  }

  .mapboxgl-ctrl-geocoder--powered-by {
    display: none !important;
  }

  input {
    color: $light-black-color;
    font-size: pxToRem(14px);

    &:focus {
      outline: none;
    }

  }

  .suggestions-wrapper ul {
    height: 100px;
    overflow-y: auto;

    .mapboxgl-ctrl-geocoder--suggestion {
      color: $dark-grey-color;
      font-size: pxToRem(14px);
      letter-spacing: 0.25px;
      font-weight: normal;
      font-family: $roboto-regular;
    }

    .mapboxgl-ctrl-geocoder--suggestion-title {
      font-weight: normal;
      font-family: $roboto-medium;
    }
  }

  &.input-disabled input {
    pointer-events: none;
    background-color: $grey-color9;
    border-color: #C6C6C6;
  }
}

.session-modal {
  .modal-dialog {
    width: 414px;
    margin: 150px auto;
    padding: 30px;
    border-radius: 8px;
    background: $white-color;
    border: 1px solid rgba(45, 48, 56, 0.4);
    box-shadow: 0px 8px 6px rgba(0, 0, 0, .16);
  }

  .modal-content,
  .modal-header {
    align-items: center;
    border: none;
  }

  .network-error-text {
    line-height: 20px;
  }

  &.network-modal {
    z-index: 1057;

    .modal-dialog {
      width: 555px;
      max-width: 555px;
    }
  }
}

.network-error-backdrop {
  z-index: 1056;
}

.search-no-records {
  width: 98%;
  min-height: 52px;
  margin: 1%;
  border-radius: 8px;
  background-color: $grey-color9;

  .no-result-text {
    font-size: 12px;
    text-transform: capitalize;
    color: $black-color2;
  }

  &.backlog-search-no-records {
    width: 96%;
    margin: 2%;
  }
}

.start-routine-warning {
  font-size: 14px;
  font-style: italic;
  margin-left: 35px;
  padding-bottom: 10px;
  color: $red-color !important;
  display: block;
  &.checkout-error {
    position: absolute;
    margin-left: 0;
    bottom: -25px;
    font-family: $roboto-bold;
    font-size: pxToRem(11px);
  }

  &.checkout-error {
    position: absolute;
    margin-left: 0;
    bottom: -38px;
    font-family: $roboto-bold;
    font-size: pxToRem(11px);
  }
}

.sch-facility-select__value-container {
  height: 28px;
  line-height: 26px;

  input {
    position: absolute !important;
  }

  .sch-facility-select__input-container {
    margin: 0;
    padding: 0;
  }
}

.add-surge-btn {
  padding: 10px 20px;

  .add-surge-label {
    font-size: pxToRem(10px);
    letter-spacing: 0.162px;
  }

  &:hover,
  &:focus,
  &.active {
    background-color: #EDECFB !important;
    color: #6D66DC;
  }

  .icon-add-user::before,
  .icon-non_service::before {
    font-size: pxToRem(14px);
  }
}

.surge-non-service-search-div {
  .search-input {
    margin: 2px auto 4px;

    .search-box {
      height: 36px;
    }
  }

  &.ad-reporting-wrapper {
    min-height: 190px;
    height: auto;

    .search-icon {
      bottom: -1px;
      top: unset;
      height: auto;
      padding: 10px;
    }

    .search-box {
      height: 40px;
    }
  }
  &.ad-reporting-wrapper {
    min-height: 190px;
    height: auto;
    .search-icon {
      bottom: -1px;
      top: unset;
      height: auto;
      padding: 10px;
    }
    .search-box {
      height: 40px;
    }
  }
}

.tracker-wrapper {
  .tracker-result.sr-dropdown-container ul {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    overflow-y: auto;
    transform: translateX(-50%);
    height: 106px;
    background: $white-color;
    border-radius: 8px;
    border: 1px solid rgba(18, 18, 18, 0.2);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16%);

    li {
      border-radius: 8px;
    }
  }
}

.surge-event-wrapper,
.surge-non-service-search-div .search-input,
.tracker-result.sr-dropdown-container ul {
  width: 95%;
}

.territory-dropdown {
  .sch-schedule-select__value-container,
  .sch-schedule-select__input-container {
 height: 40px !important;
  }
  .sch-schedule-select__input-container {
    margin-top: -5px;
  }
  input {
    &:focus {box-shadow: none !important;}
  }
  .sch-schedule-select__menu-list {
    @include breakpoint(max1366) {
      max-height: 140px;
    }
  }
}

.deactive-confirmation-list {
  max-height: 180px;
  padding: 0 15px;
  overflow-y: auto;
  text-align: left;
}
div.sch-virtual-select__menu,
div.sch-virtual-select {
  margin-top: 5px;
  // box-shadow: none !important;
}
div.sch-copycal-select.sch-facility-select__value-container,
.sch-copycal-select.sch-virtual-select.sch-facility-select__clear-indicator,
.sch-copycal-select.sch-virtual-select.sch-facility-select__indicators {
  margin-top: 0;
}
.rv-facility-select-dropdown,
.sch-facility-select__menu-notice--no-options {
  height: auto !important;
  max-height: 220px !important;
  overflow: auto !important;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid rgba(18, 18, 18, 0.2);
  // & > div {
  //   height: 220px !important;
  //   overflow-y: auto !important;
  // }
}
.sch-schedule-select__option {
  &:hover { cursor: pointer;} 
  &.sch-schedule-select__option--is-disabled {
    opacity: .4;
    background-color: transparent !important;
    .sch-schedule-select__option {background-color: transparent;}
  }
}
.e-ddl.e-popup {
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 10px;
  padding: 8px 0;
}
.rv-dropdown { margin-top: 0 !important;}
.sch-copy-cal-wrapper {
  position: absolute;
  top: -4px;
  right: 0;
  .copy-cal-btn { min-width: 182px; }
  .copy-calendar { padding-right: 10px; }
}

//Care at home schedule
.care-at-home-schedule-component {
  .sch-facility-select__menu-notice--no-options {
    border: none;
  }
  .e-tooltip-wrap.e-popup {
    background-color: #ebebeb !important;
    border: none;
    border-radius: 10px;
    padding: 10px;
    .e-tip-content { border-radius: 10px; }
  }
  .cah-patient-info {       
    font-size: pxToRem(14px);
    line-height: 1.2;
    font-family: $roboto-regular;
    color: #212529;
  }
  .cah-user-details-wrap .cah-patient-info {
    font-size: pxToRem(13px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .sch-avatar-wrapper {
    width: 26px !important;
    height: 26px !important;
  }
  .ch-home-scheduler-mainwrapper {
    height: calc(100% - 60px);
  }
  .ch-home-scheduler-wrapper {
    height: 100% !important;
  }
  .hh-order-status { margin-left: 3px;}
  .ch-home-scheduler-wrapper {
    .e-resource-left-td {
      width: 230px !important;
    }
    .e-schedule-table {
      .e-appointment {
        height: auto;
        background-color: #FFFFFF;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 8px;
        padding: 10px;
        cursor: pointer;
      }
      .e-header-date {
        display: none;
      }
      .cah-patient-info {  
        > span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100%;
          display: inline-block;
        }
      }
      .partner-icon {        
          display: inline-flex;
          width: 30px;
          min-width: 32px;
          height: 30px;
          background-repeat: no-repeat;
          background-size: contain;
          &.legacy {
            background-image: url('../images/partner-images/legacy.png');
          }
          &.natera {
            background-image: url('../images/partner-images/natera.png');
          }
          &.partner-default {
            background-image: url('../images/partner-images/partner-default.png');
          }
          &.story-health {
            background-image: url('../images/partner-images/story-health.png');
          }
          &.villagemd {
            background-image: url('../images/partner-images/villageMD.png');
          }
          &.caredx {
            background-image: url('../images/partner-images/careDX.png');
          }
          &.cinqcare {
            background-image: url('../images/partner-images/cinqcare.png');
          }
        }    
    }
  }
  .cah-header-bar {
    position: absolute;
    z-index: 9;    
  }
  .cah-header-bar-right {
    top: 8px;
    right: 15px;
  }
  .cah-header-bar-left {
    left: 245px;
    top: 9px;
    padding: 3px 8px 4px;
    background-color: #0B2032;
    border-radius: 50px;
    font-size: pxToRem(10px);
    font-family: $roboto-regular;
    color: #fff;
  }
  .header-left {
    flex-basis: 360px;
    max-width: 360px;
  }
  .scheduler-tabs-wrapper {
    .search-box {
      width: 200px;
      height: 38px;
      padding-right: 48px;
    }
    .scheduler-filters  {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .facility-filter {
      width: 100%;
      justify-content: flex-start;
      margin-bottom: 15px;
      &.gs-filter-wrapper { margin-bottom: 0;}
    }
    .day-filter {
      text-align: left;
      min-width: 230px;
    }
    .input-group {
      width: 220px;
      max-width: 220px;
      min-width: 150px;
      div.sch-virtual-select { 
        margin-top: 0; 
        z-index: 10;
      }
      &:nth-of-type(4) {
        width: 180px;
      }
      .sch-facility-select__option label {
        max-width: 165px; 
        font-family: $roboto-regular; 
      }
    }
    .mob-back {
      padding: 0 6px;
      &::before { font-size: pxToRem(12px);}
      &:hover { cursor: pointer;}
    }
    .template-wrap {
      // padding: 12px 5px;
    }
    .e-header-row {
      .e-time-slots {
        background-color: white;
        height: 20px;
        span { font-size: pxToRem(12px);}
      }
    }
    .cah-today {
      margin: 0 5px;
      font-size: pxToRem(12px);
      text-transform: uppercase;
      font-family: $roboto-bold;
      &:hover { cursor: pointer; }
    }
    .cah-next { 
      display: inline-flex;
      transform: rotate(180deg);
    }
    .cah-info-wrapper {
      max-width: 65%;
      white-space: pre-wrap;
    }
  }
  .icon {
    &::before {
        font-size: 25px !important;
        color: $blue-color1 !important;
    }

    &.redispatched,
    &.cancelled,
    &.redispatch {
        @include icomoon("refresh", 16px);
    }

    &.dropped-off {
        @include icomoon("drop-off", 16px);
    }

    &.unacknowledged {
        @include icomoon("hourglass-bottom", 16px);
    }

    &.check-in {
        @include icomoon("check-in", 16px);
    }

    &.check-out {
        @include icomoon("check-out", 16px);
    }

    &.acknowledged {
        @include icomoon("visibility", 18px);
    }

    &.unassigned {
        @include icomoon("assignment-late", 16px);
    }
    &.partial-completion {
        @include icomoon("partial-completion", 18px);
    }
    &.unscheduled{
        @include icomoon("unscheduled", 16px);
    }
    &.scheduled{
        @include icomoon("scheduled", 18px);
    }
    &.assigned-to-coordinator{
        @include icomoon("assigned-to-coordinator", 18px);
    }
    &.assigned{
        @include icomoon("assigned", 18px);
    }
    &.unclaimed{
        @include icomoon("unclaimed", 16px);
    }
  }
  .e-appointment-details {
    padding: 0 !important;
    > div { width: 100%; }
    .e-indicator.e-left-icon {
      display: none;
    }
  } 
  .e-spinner-pane .e-spinner-inner {
    display: none;
  }
  .e-schedule .e-timeline-view .e-work-cells,
  .e-schedule .e-timeline-month-view .e-work-cells {
    background-color: $white-color;
  }
  .e-schedule .e-timeline-view table tr:last-child td,
  .e-schedule .e-timeline-month-view table tr:last-child td {
    border-bottom-width: 1px;
    border-color: $input-border-color;
  }
}
.ut-phleb-wrapper {
  position: absolute;
  top: -35px;
}

.aha-timezone-wrapper {
  .sch-btn-transparent {
   
    &::after {
      content: "\e96c";
      font-family: 'icomoon';
      font-size: 18px;
      color: $grey-color11;
      border: none;
      vertical-align: middle;
    }
    &:focus,
    &:focus-within,
    &:active { 
      background-color: transparent; 
      border: none;
      box-shadow: none;
    }
  }
  .dropdown {
    padding: 3px 10px;
    margin: 0 0 0 12px;
    align-items: center;
    border: 1px solid $grey-color7 ;
    border-radius: 4px;
  }
  .timezone-name { 
    padding-right: 7px;
    margin: 0 8px 0 0 !important;
    min-width: 38px;
    text-align: center;
    border-right: 1px solid $grey-color7;
    font-size: pxToRem(14px);
  }
  .timezone-divider {
    border-right: 1px solid $grey-color7;
  }
}
.aha-timezone-select-wrapper {
  margin: 0 8px;
  // margin-top: -4px;
  .sch-schedule-dropdown,
  .cus-dropdown-container,
  .e-input-group {
    width: 65px !important;
  }
  .cus-dropdown {
    width: 165px !important;
  }
}
.check-in-select {
  width: 165px;
  min-height: 86px;
  // margin-bottom: 30px !important;
  span {
    padding-top: 0;
  }
}
.form-timezone-text {
    // min-height: 18px;
    // min-width: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: pxToRem(12px);
    color: $grey-color4;
}
.check-in-timezone-select {   
  width: 65px;  
  min-height: 86px;
  margin-right: 12px;
  // margin-bottom: 30px !important;  
  span { padding-top: 0; }
}
.sch-timezone-wrapper {
  .cus-dropdown {
    width: 165px;
  }
  
  .check-in-timezone-select { 
    margin-left: 12px; 
    .cus-dropdown-container, input {
      width: 65px !important;
    }       
  }
}
.sch-date-time-header,
.sch-date-timezone-header {
  line-height: normal;
}
.sch-date-time-header {
  font-size: pxToRem(14px);
}
.sch-date-timezone-header {
  font-size: pxToRem(13px);
  font-family: $roboto-regular !important;
}
.form-check.aha-timezone-header {
  margin-right: 0;
  margin-bottom: 4px;
  .form-check-label {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: normal;
  }
  .st-checkbox-label { padding-top: 5px; }
  .form-check-input {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }
}

.ad-headertop-wrapper .header-right {
  .search-box {
    padding-right: 65px;
  }
}

.facility-filter,
.staff-filter {
  .ad-care-modalform-wrapper {
    .sch-facility-select__control {
      min-height: 40px;
    }
  }
  .sch-schedule-select__control { height: 40px; }
  .error-message {
    .sch-facility-select__control {
      border-color: $red-color;
    }
  }
  .sch-schedule-select__control--is-disabled {
    background-color: $grey-color9;
    border: 1px solid $input-border-color;
    .sch-schedule-select__single-value {
      color: $label-color;
    }
  }
  .sch-facility-dropdown,
  .sch-facility-select__control {
    width: 100%;
    border-color: rgba(18, 18, 18, 0.2);
    font-size: 0.875rem;
    color: rgba(18, 18, 18, 0.87) !important;
    border-radius: 4px;
    font-family: $roboto-regular;

    .sch-facility-select__single-value,
    .sch-facility-select__input-container {
      color: $light-black-color;
    }

    .sch-facility-select__control--is-focused {
      .sch-facility-select__dropdown-indicator::before {
        transform: rotate(180deg);
        transition: transform 300ms ease;
        color: $light-blue-color;
      }
    }

    .sch-facility-select__indicator-separator,
    .sch-facility-select__indicator svg {
      display: none;
    }

    .sch-facility-select__dropdown-indicator {
      @include icomoon("arrow-down", 5px);
    }

    .sch-facility-select__clear-indicator {
      @include icomoon("close", 8px);

      &::before {
        padding-top: 3px;
        font-size: 10px;
        color: $grey-color;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .sch-facility-select__multi-value {
      background-color: transparent;

      .sch-facility-select__multi-value__label {
        padding-left: 0;
        font-size: 0.875rem;
      }

      .sch-facility-select__multi-value__remove {
        svg {
          color: $grey-color;
        }

        &:hover {
          background-color: inherit;
          color: inherit;
        }
      }
    }

    .sch-facility-select__placeholder {
      position: absolute;
      top: 1px;
      margin-top: 0;
    }

    .sch-facility-select__option {
      display: flex;
      align-items: center;
      height: 35px;

      label {
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
        // color: $dark-grey-color;
        font-size: pxToRem(14px);
        letter-spacing: 0.25px;
        line-height: 1.1;
        color: #767676;
        font-family: "robotoregular", sans-serif;
      }

      input {
        min-width: 18px !important;
        width: 18px !important;
        height: 18px !important;
        margin-right: 10px !important;
        margin-top: 0;
        border-color: rgba(0, 0, 0, .6);
        border-width: 3px;

        &.form-check-input:checked[type=checkbox] {
          background-color: $dark-blue-color;
          background-position: -1px;
        }
      }

      color: $dark-grey-color;
      font-size: pxToRem(14px);
      letter-spacing: 0.25px;

      &.sch-facility-select__option--is-focused {
        background-color: transparent;
      }

      &.sch-facility-select__option--is-selected,
      &:hover {
        background-color: rgba(0, 0, 0, 0.08);
      }

      &.sch-facility-select__option--is-selected input {
        background-color: $dark-blue-color;
      }
    }

    .sch-facility-select__control--is-disabled {
      background-color: $grey-color9;
    }
  }

  .dropdown-toggle {
    background: transparent;
    border: none;
    color: $black-color;
    letter-spacing: 0.14px;

    &::after {
      display: none;
    }
  }

  &.show {
    .dropdown-menu {
      &.show:hover {
        background-color: $white-color;
      }

      top: 6px !important;
      width: 295px;
      padding: 12px;
      border-radius: 8px;
      border: 1px solid rgba(18, 18, 18, 0.2);

      label {
        width: 100%;
        color: $grey-color4;
        font-family: $roboto-regular;
        line-height: 16px;
        margin-bottom: 3px;
      }

      &.search-type-dropdown {
        width: 140px;
        padding: 0;

        .search-list-name {
          padding: 12px;
          font-size: 12px;
          font-family: $roboto-medium;
          line-height: 24px;
          border-radius: 8px;

          &:hover {
            cursor: pointer;
            background-color: $light-blue-color2;
            color: $light-blue-color;
          }
        }
      }
    }

    .service-icon::before {
      transform: rotate(180deg);
      transition: transform 300ms ease;
      color: $light-blue-color;
    }
  }

  .service-icon {
    @include icomoon("arrow-down", 5px);

    &::before {
      color: $grey-color;
    }
  }
}

.sch-facility-select__menu {
  z-index: 300 !important;
}
