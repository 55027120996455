//Admin page Layout styles
.ad-main-container {
  flex: 1 1 auto;
  display: flex;
  height: 100%;
}
.schedule-component {
  .scheduler-grid { 
    ::-webkit-scrollbar {
      width: 7px;
      //background: transparent; /* make scrollbar transparent */
      // display: none;
    
    }
    ::-webkit-scrollbar-thumb {
      background: $grey-color6;
    }
    &.in-drag-mode {
      overflow: clip !important;
      .scheduler-table-wrapper {
        overflow: clip !important;
      }
    }
  }
  .scheduler-backlogs {
    ::-webkit-scrollbar {
      width: 0; 
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }
}
.ad-right-wrapper {
  display: flex;
  flex-direction: column; 
  flex: 0 0 100%;
  max-width: 100%;
  
  .ad-right-content-wrapper {
    height: calc(100% - 18px);
    padding: 20px 15px 20px 72px;
  }
  .ad-right-stat-order-content-wrapper {
    // height: calc(100% - 18px);
    padding: 20px 15px 10px 72px;
  }
  //overflow-y: auto;
  .ad-headertop-wrapper {
    padding: 0 0 15px 0;
    &.ad-headertop-new {
      height: auto;
      width: 100%;
    }
    &.scheduler-header {
      padding: 0 0 20px 0;
      .header-left h1 { padding-bottom: 0;}
    }
    .aha-user-dropdown {
      img {
        width: 36px;
        height: 36px;
      }
      .aha-logout-dropmenu.show {
        padding: 5px 0;
        width: 148px;
        background: $white-color;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        border: none;
        // filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
        &:hover {
          cursor: pointer;
          a {
            background-color: transparent;
          }
          
        }
      }
      .dropdown-item {
        font-family: $roboto-regular;
        font-size: pxToRem(14px);
        color: $dark-grey-color;
        text-decoration: none;
        &:active {
          background-color: transparent;
        }
      }
    }
  }
  .header-left {
    h1 {
      color: $blue-color1;
    }
    p {
      color: $dark-blue-color;
    }
  }
  .ad-headerbottom-wrapper {
    height: calc(100% - 56px);
    padding: 0;
    .tab-content {
      position: relative;
      height: calc(100% - 34px);
      .tab-pane {
        height: 100%;
      }
    }
    div.ad-navtab {
      height: calc(100% - 34px) !important;
    }
    .add-stat-order-btn{
      @include icomoon("receipt", 16px);
      &:before{
        color: $link-blue-color;
        &:hover{
          color:$blue-color;
        }
      }
    }
    
    .ad-toggle-switch {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      .ad-switch {
        padding-right: 8px;
        line-height: 20px;
        letter-spacing: 0.18px;
        color: $dark-grey-color;
        font-family: $roboto-regular;
      }
    }
    .adduser-row {
      position: relative;
      min-height: 45px;
    }
  }
}
.add-user-btn {
  padding: 12px 25px 12px 12px;
  background: transparent;
  border: none;
  border-radius: 11px;
  color: $light-blue-color;
  letter-spacing: 0.7px;
  &:hover,
  &:hover .icon-add-user::before,
  &:hover .icon-care-facility::before,
  &:hover .icon-add-hospital::before,
  &:hover .icon-add-lab::before,
  &:focus-visible {
    color: $blue-color !important;
    background-color: transparent !important;
  }
  &:disabled,
  &:disabled .icon-add-user::before {
    color: $grey-color5;
  }
  &:focus,
  &:focus-visible {
    box-shadow: 0 0 0 0.25rem $blue-color3 !important;
  }
  span:first-child {
    padding-right: 8px;
  }
}
//Access Group Status Display
.access-group-text {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 5px 10px;
  margin-right: 8px;
  border: 3px solid transparent;
  border-radius: 8px;
  font-size: pxToRem(13px);
  color: $white-color;
  background-color: $blue-color6;
  .access-title {
    padding-left: 10px;
    color: $grey-color9;
  }
  .access-group-icon {
    @include icomoon("check-circle", 16px);
    &::before {
      color: $blue-color8;
    }
  }
}

//Admin page data grid styles starts
.ad-grid-wrapper {
  width: 100%;
  height: calc(100% - 32px);
  .e-grid {
    height: 100%;
    border: none;

    //Input Field
    ::placeholder {
      color: $grey-color4;      
    }
    .e-sicon { display: none !important; }
    .e-input,
    .e-input:hover,
    .e-input:focus {
      width: 100% !important;
      padding-right: 8px;
      font-size: pxToRem(14px);
      font-family: $roboto-regular;
      line-height: 20px;
      letter-spacing: 0.18px;
      color: $dark-grey-color;
    }
    .e-input-group:not(.e-disabled) .e-input-group-icon:hover,
    .e-input-group.e-control-wrapper:not(.e-disabled) .e-input-group-icon:hover {
      background-color: $grey-color11;
      &::before {
        color: $white-color;
      }
    }
    .e-input-group,
    .e-cc-searchdiv {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width:calc(100% - 1px);
    }
    .e-search-icon.e-icons,
    .e-ccsearch-icon,
    .e-searchclear.e-icons {
      height: 40px;
      min-width: 39px !important;
      border-left-width: 1px;
    }
    .e-searchinput,
    .e-cc-searchdiv .e-input {
      height: 40px;
    }

    //Toolbar - Search Box And Column Chooser
    .e-toolbar {
      height: 57px !important;
      border: 1px solid $grey-color7;
      .e-toolbar-items {
        background-color: $white-color2;
        .e-toolbar-right {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 0 7px;
          .e-toolbar-item {
            &.e-search-wrapper {
              padding: 0;
              .e-input-group {
                &.e-search {
                  width: 356px;
                  border: 1px solid $grey-color7;
                  border-radius: 4px;
                  background-color: $white-color;
                  opacity: 1;
                  .e-search-icon {
                    min-width: 39px;
                    height: 40px;
                    margin-top: -1px;
                    &:hover {
                      background-color: $grey-color11;
                      color: $white-color;
                      &::before {
                        color: $white-color;
                      }
                    }
                    @include icomoon("Search", 17px);
                    &::before {
                      color: $grey-color11;
                      font-size: pxToRem(17px);
                    }
                  }
                }
              }
              .e-input-group:not(.e-disabled):not(.e-float-icon-left)::before,
              .e-input-group:not(.e-disabled):not(.e-float-icon-left)::after {
                background: transparent;
              }
            }
            &.e-cc-toolbar {
              margin: 0;
              padding: 0;
              position: absolute;
              right: 205px;
              .e-tbar-btn {
                &:hover,
                &:active,
                &:focus {
                  background: none;
                  .e-icons {
                    color: $dark-grey-color;
                  }
                }
                .e-tbar-btn-text {
                  color: $dark-grey-color;
                  font-family: $roboto-regular;
                  font-size: pxToRem(14px);
                  padding: 4px 12px !important;
                }
                .e-columnchooser-btn.e-btn-icon {
                  margin-top: 0 !important;
                  padding:0 4px 3px;
                  &::before {
                    font-size: pxToRem(12px);
                  }
                }
              }
            }
            .clear{
              .e-clear-icon{ 
                height: 40px; 
                min-width: 39px; 
                border-left: 1px solid $grey-color7;
                &::before{
                  font-size: 1.125rem;
                }
                &:hover{
                  background-color: $grey-color11;
                  &::before{
                    color: $white-color;
                  }
                } 
              }
            }
          }
        }
      }
    }

    //Data Grid Header
    .e-gridheader {
      border-color: $grey-color7;
      border-top: transparent;
      padding-right: 0 !important;
      .e-headercontent {
        border-color: $grey-color7;
        .e-table {
          border-collapse: inherit;
          border-right: 1px solid $grey-color7;
          .e-columnheader {
            height: 48px;
            .e-headercell {
              padding: 8px 15px 8px;
              &:not(:last-child) {
                border-right: 1px solid $grey-color7;
              }
              &:first-child {
                border-left: 1px solid $grey-color7;
              }
              .e-headertext {
                font-family: $roboto-medium;
                font-size: pxToRem(16px);
                font-weight: 500;
                letter-spacing: 0.5px;
                color: $label-color;
              }
              .e-filtermenudiv {
                padding: 1px 0;
              }
              .e-icon-filter {
                @include icomoon("filter", 14px);
                &.e-filtered:before {
                  color: #FF8381;
                }
              }
              .e-icon-ascending {
                @include icomoon("arrow-upward", 14px);
              }
              .e-icon-descending {
                @include icomoon("arrow-downward", 14px);
              }
              .e-icon-descending,
              .e-icon-ascending{
                &::before {
                  color:$grey-color11;
                }
              }

              .e-icon-ascending::before,
              .e-icon-descending::before {
                position: absolute;
                top: 18px;
                right: 35px;
              }
            }
          }
        }
      }
    }
    #grid-component-user_excelexport,
    #grid-component-facility_excelexport,
    #grid-component-hospital_excelexport,
    #grid-component-lab_excelexport{
      // .e-excelexport{
      //   display: none;
      // }
      // span{
      //   font-family: $roboto-regular;
      // }
      // &:hover,&:active,&:focus{
      //   background-color: transparent;
      //   span{
      //     color: $dark-grey-color;
      //   }
      // }
    }
    //Data Grid Content
    .e-gridcontent {
      height: calc(100% - 165px);
      .e-content {
        height: 100% !important;
        // overflow: auto !important ;
        border-right: 1px solid $grey-color7;
        border-left: 1px solid $grey-color7;
        .e-table {
          // height: 100%;
          tr:hover {
            cursor: pointer;
          }
          tr.e-row:hover td,
          td.e-active {
            background-color: $blue-color5 !important;
          }
          .erow {
            height: 48px;
          }
          .e-rowcell {
            font-family: $roboto-regular;
            font-size: pxToRem(14px);
            padding: 0 10px;
            height: 48px;
            color: $label-color !important;
            &:first-child {
              border-left: 1px solid $grey-color7;
              text-transform: capitalize;
            }
          }
        }
      }
    }

    //Data Filter Dialog Column Chooser
    .e-ccdlg {
      box-shadow: 0px 3px 6px rgba(0,0,0,.16);
      border-radius: 4px;
      .e-dlg-header-content {
        display: none;
        padding: 0;
      }
      .e-dlg-content {
        padding: 14px 10px;
        .e-main-div {
          padding-top: 10px;
        }
        .e-cc-searchdiv {
          opacity: 1;
          border-color: $grey-color7;
          .e-input {
            padding: 0 0 0 10px;
          }
          .e-ccsearch-icon {
            @include icomoon("Search", 17px);
            border-color: $grey-color7;
            &:hover::before,
            &:hover.e-cc-cancel::before {
              color: $white-color;
            }
            &.e-icons {
              opacity: 1;
              color: $grey-color11;
            }
            &.e-cc-cancel::before {
              content: "\e7e7";
              font-family: "e-icons";
              font-size: pxToRem(18px);
              color: $grey-color11;
            }
          }
        }
        .e-cc-contentdiv {
          .e-ccul-ele {
            padding: 0;
            margin: 0;
            .e-cclist {
              padding: 7px 5px;
              &:hover {
                background-color: $grey-color9;
              }
              .e-checkbox-wrapper {
                display: flex;
                padding-bottom: 5px;
                .e-label {
                  font-family: $roboto-regular;
                  font-size: pxToRem(14px);
                  margin-left: 12px !important;
                  color: $label-color;
                  line-height: 1.2;
                }
              }
            }
          }
        }
      }
      .e-footer-content {
        border-color: $grey-color7;
      }
    }

    //Data Filter Dialog Data Grid
    .e-checkboxfilter {
      box-shadow: 0px 3px 6px rgba(0,0,0,.16);
      border-radius: 4px;
      .e-dlg-content {
        padding-left: 0;
        padding-top: 0;
        .e-searchbox {
          margin: 12px 10px 0;
          padding: 0;
          .e-input-group {
            border-color: $grey-color7;
            margin: 0 0 10px 0;
            .e-searchinput {
              padding-left: 10px;
              font-family: $roboto-regular;
            }
            .e-search-icon {
              @include icomoon("Search", 17px);
              &:hover {
                background-color: $grey-color11;
                &::before {
                  color: $white-color;
                }
              }
            }
            .e-chkcancel-icon::before {
              font-size: pxToRem(18px);
            }
          }
        }
        .e-spinner {
          .e-checkboxlist {          
            margin: 0 10px 10px;
              height: 168px;
              min-height: 168px;
              @include breakpoint(max1200) {
                height: 120px;
              }
              @include breakpoint(min1920) {
                height: 200px;
              }
            .e-ftrchk {
              padding: 3px 10px 7px;
              &:not(:last-child){
                margin-bottom: 12px;
              }
            }
          }
        }
      }
      @media screen and (min-width:767px) and (max-width:1366px){
        max-height: 300px !important;
        min-height: auto;
        .e-dlg-content{
          overflow: hidden;
        }
      }
    }
  }
  .e-grid .e-focused:not(.e-menu-item):not(.e-editedbatchcell) {
    box-shadow:none !important;
  }
  .e-toolbar-left {
    //right: 104px !important;
    left: unset !important;
    z-index: 1 !important;
  }
}

//Modal Styles
.ad-adduser-modal {
  &.modal{
    background-color: rgba(0, 0, 0, 0.04);
    z-index: 100000;
  }
  .modal-dialog {
    max-width: 1095px;
    height: calc(100% - 6rem);
    @include breakpoint(max1024) {
      max-width: 900px;
      min-height: 690px;
      height: 690px;
    }
  }
  .modal-content {
    height: 100%;
    padding: 20px;
    background-color: $white-color3;
    border:none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  .modal-header {
    align-items: flex-start;
    padding: 0 0 0 5px;
    border-bottom: none;
    position: relative;
    .btn-close {
      margin-right: 0;
      background-image: none;
      opacity: 1;
      @include icomoon("close", 13px);
      &::before {
        color: $grey-color11;
      }
    }
    .modal-title {
      padding-bottom: 20px;
      color: $blue-color1;
    }
    .error-message-alert {
      position: absolute;
      left: 0;
      right: 0;
      width: 35%;
      margin: 0 auto;
      font-size: pxToRem(12px);
      line-height: 1.2;
      text-align: center;
      color: $red-color;
    }
  }

  .access-status-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    .chipsetcomponent-wrapper {
      
      flex-basis: 55%;
      &.profile-screen {
        .access-group-text {
          margin: 5px 8px 5px 0;
          padding-bottom: 3px;
          .access-title{
            padding-bottom: 2px;
          }
        }
      }
    }
    .statuscomponent-wrapper {
      flex-basis: 40%;
      .status-block {
        &.profile-screen {
          .e-chip {
            margin: 5px 0 0;
            height: 32px;
            padding: 14px 10px;
            cursor: default;
            &:active,
            &.e-focused{
              box-shadow:none;
            }
          }
        }
        &.edit-screen {
          .aha-modalform-group {
            width: 370px;
          }
          label {
            color: $grey-color4;
            font-family: $roboto-regular;
          }
          .e-input-group {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            font-family: $roboto-regular;
            color: $label-color;
            &:hover {
              border-color: $grey-color4;
              color: $grey-color4;
            }
            &:active,
            &:focus {
              box-shadow: 0 0 0 4px rgb(187, 218, 236) !important;
              outline: none;
              border-color: $grey-color4 !important;
            }
            &.e-disabled,
            &.e-disabled:hover {
              background-color: #f2f2f2;
              border-color: #C6C6C6;
              color: #121212;
            }
          }
        }
      }
    }
  }
  .modal-footer {
    padding: 1rem 1.5rem;
    padding-right: 16px;
    border-top: none;
    .primary-btn.primary-btn-outline {
      font-family: $roboto-medium;
      font-size: pxToRem(14px);
      &.outline.btn-primary:hover {
        border-color: $blue-color;
        color: $blue-color;
      }
    }
  }
  .modal-body {
    padding: 0 12px 0 5px;
    height: calc(100% - 225px);
    overflow-y: auto;
    @media screen and (min-width: 1920px) {
      height: 100%;
      overflow: auto;
    }
    .headline6 {
      position: relative;
      color: $dark-blue-color;
      letter-spacing: 0.15px;
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -5px;
        border-bottom: 1px solid $grey-color4;
      }
    }
    label {
      padding-bottom: 5px;
      line-height: 1.2;
      color: $grey-color4;
      letter-spacing: 0.4px;
    }

    .facility-service-error{
      color:$red-color;
    }
    
    .aha-modalform-wrapper {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 25px;

      // &.vertical-center-form-group .aha-modalform-group {
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: center;
      // }

      //Phone Number With Extension
      .form-phext-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: end;
        min-width: 240px;
        .phno-field {
          flex-basis: 80%;
          &.cell-phno-field { flex-basis: 100%; }
        }
        .extension-field{
          flex-basis: 18%;
          input{
            &.form-phext-input{
              border-color: rgba(18, 18, 18, 0.2);
            }
          }
        }
        .form-phext-input{
          min-width: auto;
        }
      }
      .tt-gmap-wrapper {
        flex: 0 0 100%;
        margin-bottom: 10px;
        margin-top: -15px;
      }
      .aha-modalform-group {
        min-height: 88px;
        //max-width: 240px;
        padding: 0;
        margin-right: 20px;
       
        label {
          display: block;
        }
        input,
        textarea {
          height: 40px;
          min-width: 240px;
          width: 100%;
          font-size: pxToRem(14px);
          border-radius: 4px;
          border: 1px solid rgba(18,18,18,0.2);
          padding-left: 10px;
          letter-spacing: 0.18px;
          color: $label-color !important;
          font-family: $roboto-regular;
          &:disabled {
            background-color: $grey-color9;
            color: $label-color;
          }
          &:active,
          &:focus {
            box-shadow: 0 0 0 4px rgb(187, 218, 236);
            outline: none;
            border-color: $blue-color7;
          }
          // &:hover {
          //   border-color: $grey-color4;
          //   color: $grey-color4;
          // }
          &:disabled:hover{
            border-color:$grey-color7;
          }
        }
        textarea {
          height: 172px;
          min-height: 40px;
        }
        &.error-message {
          label,
          .error-message-text {
            color: $red-color;
          }
          .sch-facility-select__option {
            label { color: $grey-color4;}
          }
          .error-message-text {
            font-family: $roboto-regular;
            font-size: pxToRem(12px);
            line-height: 16px;
            letter-spacing: 0.33px;
            padding-top: 5px;
          }
          input, textarea {
            border-color: $red-color;
          }
        }
        &.facility-status-drop{
          &.aha-modalform-group{
            padding-top: 10px;
            margin-right: 0;
          } 
        }
        &.ch-form-group { 
          flex: 0 0 35.5%; 
          max-width: 35.5%;
        }
      }
      .modal-droprdown-input{
        border: none;
        .e-input-group-icon.e-ddl-icon.e-search-icon,
        .e-input-group-icon{
          position: absolute !important;
          top:5px;
          right:0;
        }
        &.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-disabled{
          background-color: #F2F2F2;
          border: transparent;
        }
      }
      .aha-modalform-group2 {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 100%;
        .aha-modalform-group {
          max-width: 368px;
          flex: 0 0 368px;

          &.error-message {
            .e-input-group {
              border-color: $red-color!important;
            }
            .error-message-text {
              font-family: $roboto-regular;
              font-size: pxToRem(12px);
              line-height: 16px;
              letter-spacing: 0.33px;
              padding-top: 5px;
            }
          }
          .e-dropdownbase,
          .e-dropdownlist {
            border: none;
            &:focus {
              box-shadow: none;
            }
          }
          .e-input-group:not(.e-disabled):active:not(.e-success):not(.e-warning):not(.e-error),
          .e-input-group.e-control-wrapper:not(.e-disabled):active:not(.e-success):not(.e-warning):not(.e-error) {
            box-shadow: 0 0 0 4px rgb(187, 218, 236);
          }
          .e-input-group:not(.e-success):not(.e-warning):not(.e-error),
          .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
            border-color: $grey-color7;
            &:hover {
              // border-color: $grey-color4;
              // color: $grey-color4;
              border-width: 1px;
            }
            &.e-disabled:hover{
              border-color:$grey-color7;
            }
          }

          .e-input-group {
            height: 40px;
            font-size: pxToRem(14px);
            color: $grey-color4;
            border-radius: 4px;
            border: 1px solid $grey-color7;
            font-family: $roboto-regular;
            display: flex;
            justify-content: center;
            align-items: center;
            &.e-disabled {
              background-color: $grey-color9 !important;
              color: $grey-color4 !important;
            }
            
            input {
              color: $label-color !important;
            }

            &:active {
              border-color: $grey-color4 !important;
            }
            .e-input::placeholder {
              color: $grey-color4;
            }
          }
        }
      }
      .aha-modalform-group3{
        display: flex;
        flex-wrap: wrap;
        .aha-modalform-group{
          flex-basis: 31%;
        }
      }

      &.ad-care-modalform-wrapper {
        justify-content: flex-start;
        .aha-modalform-group {
          margin-right: 20px;
        }
        .flex-50 {
          flex: 0 0 48%;
          max-width: 48%;
        }
        .flex-35 {
          flex: 0 0 36%;
          max-width: 36%;
        }
        .activation-date-field {
          flex: 0 0 215px;
          max-width: 215px;
          input { min-width: 200px; }
        }
        .flex-75 {
          flex: 0 0 73.4%;
          max-width: 73.4%;
        }
        .mr-3 {
          margin-right: 25px;
        }
        .ad-radio-group {
          .form-check-label {
            color: $label-color;
            margin-top: 7px;
          }
        }
      }
      .ad-weekday-group {
        .ac-weekdays-selector input {
          display: none !important;
        }

        .ac-weekdays-selector input[type="checkbox"]:disabled + label {
          background: $grey-color11;
          cursor: default;
        }

        .ac-weekdays-selector input[type="checkbox"] + label {
          display: inline-block;
          background: $grey-color7;
          height: 40px;
          width: 40px;
          line-height: 40px;
          text-align: center;
          font-family: $roboto-medium;
          color: $dark-grey-color;
          cursor: pointer;
          &:hover {
            color: $label-color;
            background-color: $grey-color11;
          }
          &:nth-child(2) {
            border-radius: 5px 0 0 5px;
          }
          &:last-child {
            border-radius: 0 5px 5px 0;
          }
        }
        .ac-weekdays-selector input[type="checkbox"]:checked + label {
          background: $dark-blue-color;
          color: $white-color;
          &:hover {
            background-color: $blue-color2;
          }
        }
        .tooltip-modal{
          position: relative;
          display: inline-block;
          .tooltiptext-modal{
            visibility: hidden;
            width: 180px;
            background-color:$white-color;
            color: $label-color;
            text-align: center;
            border: 1px solid $grey-color4;
            border-radius: 6px;
            padding: 5px 0;
            position: absolute;
            z-index: 1;
          }
          &:hover .tooltiptext-modal{
            visibility: visible;
            opacity: 1;
          }
        }
        .ml-weekday {
          flex: 0 0 20%;
        }
        .ml-closed-text,
        .ml-choose-time-wrapper {
          flex: 1;
        }
      }
      .courier-m-search .mapboxgl-ctrl-geocoder {
        width: 100%;
        max-width: 100%;
        border-width: 0;
        box-shadow: none;
        input { 
          padding-left: 30px; 
        }
        .mapboxgl-ctrl-geocoder--icon-search { top: 10px; }
        .suggestions-wrapper ul { height: 200px; }
      }
      .st-form-group-full {
        flex: 0 0 90%;
        max-width: 90%;
        min-height: auto;
        margin: 0 0 20px;
        .st-checkin-out-details {
          padding-top: 6px;
          color: $grey-color4;
        }
    }
    }

    .aha-modalform-group.rt-dropdown-wrapper {
      .e-ddt input[readonly].e-chip-input,
      .e-ddt .e-dropdowntree.e-chip-input,
      .e-ddt input[readonly].e-input.e-chip-input {
        width: 0 !important;
      }
      .e-input-group {
        height: auto !important;
        min-height: 40px;
        &.e-valid-input {
          justify-content: flex-start;
          .e-input-group-icon.e-ddt-icon.e-icons {
            top: 0;
            position: absolute;
            right: 0;
          }
          &.e-icon-anim .e-ddt-icon {
            transform: rotate(0);
          }
          &.e-input-focus input {
            width: 90%;
          }
          &.edit-popup {
            &.e-icon-anim {
              .e-input-group-icon.e-ddt-icon.e-icons {
                top: 5px;
              }
            }
            &.e-input-focus {
              .e-input-group-icon.e-ddt-icon.e-icons {
                top: 5px;
              }
            }
          }
        }
        .e-overflow.e-show-text {
          color: $label-color;
        }
        .e-ddt .e-chips > .e-chipcontent {
          color: $white-color;
        }
        .e-input {
          min-width: 0;
          border: none;
        }
        &.e-show-chip.e-show-dd-icon .e-clear-icon {
          right: 20px;
          top: 10px;
        }
      }
      .e-ddt.e-input-group.e-control-wrapper.e-show-text.e-show-dd-icon .e-clear-icon {
        right: 20px;
        top: 10px;
      }
      .e-chips-wrapper .e-chips,
      .e-chips-wrapper .e-chips:hover{
        background-color: $grey-color8;
      }
      .rt-dropdown-dialog{
        &.e-icon-anim{
          .e-icons{
            transform: rotate(0);
          }
        }
      }
      .rt-facility-drop.e-valid-input .e-input-group-icon.e-ddt-icon.e-icons{
        top:5px;
      }
    }
    .e-input-group,
    .e-ddl.e-input-group.e-control-wrapper {
      .e-input-group-icon,
      .e-ddl-icon {
        position: relative;
        @include icomoon("arrow-down", 5px);
        &:hover {
          background: transparent;
        }
      }
      &.e-icon-anim {
        .e-ddl-icon,
        .e-ddt-icon {
          transform: rotate(180deg);
          transition: transform 300ms ease;
          &::before {
            color: $light-blue-color !important;
          }
        }
      }
      .e-input[readonly] {
        font-size: pxToRem(14px);
        background-color: transparent !important;
        // &:hover { border: 1px solid $grey-color4 !important; }
        &:hover{ 
          // border: 1px solid rgba(18, 18, 18, 0.2) !important; 
          border: none !important;
        }
      }
      #acf-facility-type:hover,
      // #status:hover,
      #acf-timezone:hover,
      #lab-type:hover,
      #acf-lab-timezone:hover {
        border: 1px solid rgba(18, 18, 18, 0.2) !important;
      }
    }
    .e-ddt.e-input-group.e-control-wrapper.e-show-chip .e-ddt-icon,
    .e-ddt.e-input-group.e-control-wrapper.e-show-text .e-ddt-icon {
      position: relative;
      transform: none;
      transition: none;
    }

    //Views User Profile
    .region-territory-wrapper {
      .caption {
        color: $grey-color4;
      }
      .rt-view {
        margin-top: 28px;
        color: $label-color;
      }
      &.facility-view-rt{
        min-width: 240px;
      }
      &.facility-view-rt + .aha-modalform-group{
        .e-input-group:not(.e-success):not(.e-warning):not(.e-error).e-disabled,
        .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error).e-disabled{
          border: 1px solid rgba(18, 18, 18, 0.2);
        }
      }
    }

    .chipsetcomponent-wrapper {
      .chipsetcomponent-roles {
        display: flex;
        flex-wrap: wrap;
        .e-chip-list {
          padding: 5px 0;
          .e-chip-text {
            letter-spacing: 0.15px;
            margin-top: 5px;
          }
          .e-chip.e-primary {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 13px 10px;
            margin: 5px 10px 5px 0;
            font-family: $roboto-regular;
            font-size: pxToRem(13px);
            color: $dark-grey-color;
            background-color: $grey-color7;
            border: 3px solid transparent;
            border-radius: 8px;
            letter-spacing: 0.18px;
            &::before {
              margin: auto;
            }
            &:active {
              box-shadow: none;
            }
            &.e-active {
              background-color: $dark-blue-color;
              color: $white-color;
              border-radius: 8px;
              @include icomoon("check-circle", 16px);
              &:before {
                color: $white-color;
                height: 18px;
              }
              &::before {
                margin: 0 8px 0 -4px;
              }
            }
          }
        }
      }
      .error-message-text {
        font-family: $roboto-regular;
        font-size: pxToRem(12px);
        line-height: 16px;
        letter-spacing: 0.33px;
        padding-top: 5px;
        color: $red-color;
      }
    }
    &.audit-modal{
      overflow-y: hidden;
    }
  }
  .audit-logs-wrapper {
    .audit-log-title {
      margin-bottom: 10px;
      color: $dark-blue-color;
    }
    .audit-log-table { width: 70%;}
    th {
      width: 22%;
      font-size: pxToRem(12px);
      color: $blue-color11;
      background-color: transparent;
    }
    td {
      font-size: pxToRem(13px);
      color: $grey-color4;
      font-style: italic;
      background-color: transparent;
    }
    .ad-log-viewmore {
      display: inline-block;
      width: 70%;
      cursor: pointer;
      @include icomoon("arrow-down", 6px);
      &::before { 
        padding-right: 12px; 
        color: $light-blue-color;
      }
    }
  }
}
//Region Territory Dropdown
.rt-dropdown-dialog.e-ddt.e-popup {
  overflow-y: hidden;
  border-radius: 4px;
  padding-right: 10px;
  padding-top: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  .e-checkbox-wrapper {
    .e-frame {
      position: relative;
      width: 18px;
      height: 18px;
      padding: 2px 0;
      line-height: 10px;
      &.e-icons {
        border: 3px solid rgba(0,0,0,.6);
        &:hover {
          border-color: $grey-color12;
        }
        &.e-check,
        &.e-stop {
          background-color: $dark-blue-color;
          border: none;
          border-radius: 2px;
          &:hover {
            background-color: $blue-color2;
          }
          &::before {
            position: absolute;
            top: 4px;
            left: 0;
            right: 0;
            font-size: pxToRem(10px);
            color: $white-color;
          }
        }
        &.e-check {
          content: "\e727";
        }
      }
    }
  }
  .e-filter-wrap {
    border: none;
    padding: 10px;
    input {
      font-family: $roboto-regular;
      font-size: pxToRem(14px);
      color: $label-color;
      height: 40px;
    }
  }
  .e-selectall-parent {
    border-color: $grey-color7;
    padding: 10px;
    line-height: 1.5;
    .e-all-text {
      font-family: $roboto-regular;
      font-size: 0.875rem;
      color: $dark-grey-color;
    }
  }
  .e-popup-content.e-dropdown {
    // margin-top: 8px;
    max-height: 142px !important;
    .e-list-item {
      &:hover {
        color: $dark-grey-color;
        background-color: $grey-color9;
      }
      &.e-active.e-node-focus {
        .e-text-content .e-list-text,
        .e-icons {
          color: $dark-grey-color;
        }
      }
      .e-fullrow {
        background-color: transparent;
        border-color: transparent;
      }
      .e-list-text {
        font-family: $roboto-regular;
        font-size: pxToRem(14px);
        padding-left: 12px;
        color: $dark-grey-color;
      }
      &.e-active {
        &.e-list-text {
          color: $dark-grey-color;
        }
        &.e-icons::before {
          color: $grey-color;
        }
      }
      ul {
        padding-left: 20px;
      }
      .e-icons {
        color: $grey-color;
      }
      .e-icons.interaction {
        @include icomoon("arrow-down-outlined", 6px);
        &::before{
          color:$grey-color;
        }
        padding-left: 5px;
        transform: rotate(-90deg);
        &.e-icon-expandable {
          padding-left: 5px;
        }
        &.e-icon-collapsible {
          transform: rotate(0);
        }
      }
    }
  }
  &.rt-facility-drop{
    padding: 0;
    .e-dropdown{  
      margin-right: 10px;
    }
    .e-filter-wrap{
      padding:10px 10px 0;
    }
    .e-treeview {
      .e-hover{
        background-color: transparent;
      }
      .e-list-item.e-active > .e-fullrow{
        background-color: rgba(0, 0, 0, 0.08);
      }
      .e-list-item:hover{
        background-color: transparent;
      }
      .e-level-2.e-hover .e-fullrow{
        background-color: rgba(0, 0, 0, 0.08);
      }
    }
  } 
}


.ad-navtab {
  position: relative;
  padding-bottom: 8px;
  border: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  &.nav-tabs::before {
    content: " ";
    position: absolute;
    bottom: 3px;
    display: block;
    width: 100%;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: $dark-blue-color;
  }
  .nav-link {
    position: relative;
    padding: 5px 10px;
    margin-bottom: 0;
    border: none;
    transition: all 0.15s ease;
    font-family: $roboto-regular;
    font-size: pxToRem(16px);
    letter-spacing: 0.9px;
    line-height: 24px;
    color: $grey-color4;
    background-color: transparent;
    &.active {
      color: $dark-blue-color;
      &::before {
        content: " ";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 4px;
        display: block;
        border-radius: 0 0 2px 2px;
        background-color: $dark-blue-color;
      }
    }
    &:hover {
      color: $dark-blue-color;
    }
  }
}
.cus-dropdown-container {
  @include icomoon("arrow-down", 5px);
  &::before {
    position: absolute;
    right: 12px;
    top: 18px;
  } 
  input { 
    padding: 2px 10px; 
    &:hover { cursor: pointer; }
  }
  .cus-dropdown {
    position: absolute;
    padding: 4px 0;
    margin: 8px 0;
    max-height: 200px;
    width: 100%;
    overflow-y: auto;
    background-color: $white-color;
    border: 1px solid rgba(18, 18, 18, 0.2);
    border-radius: 4px;
    .cus-dropdown-options {
      padding: 8px 12px;
      width: 100%;
      font-size: 0.875rem;
      letter-spacing: 0.25px;
      color: $dark-grey-color;
      &:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.08);
      }
      &.disabled,
      &:disabled {
        opacity: .5;
        cursor: default;
      }
      &.active {
        font-family: $roboto-medium;
        background-color: rgba(0, 0, 0, 0.08);
      }
    }
   
  }
  &.cus-dropdown-open {
    &::before {
      transform: rotate(180deg);
      transition: transform 300ms ease;
      color: $light-blue-color;
    }
    .cus-dropdown { z-index: 400; }
  } 
}
.ad-deactivation-confirmation {
  z-index: 1057;
  &.sc-copycal-modal {
    .modal-dialog {
      height: auto;
      margin-top: 90px;
    }
  } 
}
.ad-reporting-manager-wrapper {
  width: 100%;
  max-height: 196px;
  padding: 10px 15px 12px;
  border-radius: 5px;
  border: 1px solid #D4D4D4;
 
  .ad-reporting-manager-list {
    max-height: 156px;
    overflow-y: auto;
    li {
      margin-bottom: 10px;
      margin-right: 0;
      &:last-child { margin-bottom: 0; }
      .form-check {
        display: flex;
        align-items: center;  
        &:last-child { margin-bottom: 0; }
      }
      .form-check-input {
        flex: 0 0 20px;
        max-width: 20px;
      }
      .form-check-label {
        text-transform: capitalize;
        color: $blue-color11 ;
      }
    }
  }
}
.flex-50 {
  flex: 0 0 50%;
  max-width: 50%;
}
.min-w-auto {
  min-width: auto !important;
}
.assigned-manager {
  max-width: 100%;
  min-height: 40px;
  padding: 10px 15px;
  background-color: #EDECFB;
  border-radius: 5px;
  span { 
    text-transform: capitalize;
    color: #1B1B1D; 
  }
}
.no-manager-found {
  background: #F2F2F2;
    height: 52px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-top: 10px;
}
.e-list-item {
  text-transform: capitalize !important;
}
.auditlog-wrapper{
  height: calc(100% - 42px);
  display: flex;
  flex-direction: column;
  table.table>:not(caption)>*>* {
    background-color: transparent;
  }
  .auditlog-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
    background-color: #f7f7f8;
  }
  .auditlog-list {
    // flex: 0 0 82%;
    height: calc(100% - 92px);
    .table-responsive,
    .audit-log-table {
      // height: 100%;
      margin: 0;
    }
    .table-responsive {
      height: 100%;
    }
  }
  .search-box{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    border: 1px solid #C6C6C6;
    border-radius: 4px;
    background-color: white;
  }
  input{
    width: 211px;
    height: 40px;
    padding-left: 10px;
    font-size: 0.875rem;
    font-family: "robotoregular", sans-serif;
    line-height: 20px;
    letter-spacing: 0.18px;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    outline: 0;
    color: #313131;
  }
  .default-clear-btn{
    top: 10px;
    right: 50px;
  }
  .search-icon{
    color: #919191;
    padding-right: 12px;
    @include icomoon("Search", 16px);
    &::after {
      content: ' ';
      position: absolute;
      top: 0;
      right: 38px;
      display: block;
      height: 40px;
      border-left: 1px solid #C6C6C6;
    }
  }
  .export-excel-btn button{
    display: flex;
    align-items: center;
    font-family: "robotomedium", sans-serif;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 16px;
    letter-spacing: 0.098px;
    color: #6D66DC;
    border: none;
    background: transparent;
    cursor: pointer;
    span{
      padding-right: 10px;
    }
    .e-excelexport {
        @include icomoon("export", 16px);
    }
    &:disabled{
      color: #767676;
      cursor: unset;
      .e-excelexport:before{
        color: #767676;
      }
    }
  }
  table tbody, table thead{
      display: block;
  }
  table{
    thead{
      border-bottom: 1px solid #767676;
      tr{
        display: flex;
      }
      th{
        width: 25%;
        font-size: 12px;
        color:#1a1a1b;
        &:last-of-type{
          width:50%;
        }
      }
    } 
    tbody{
      max-height: calc(100% - 34px);
      // max-height: 210px;
      overflow: auto;
      color: #6b6b6b;
      font-size: 14px;
      font-style: italic;
      // @include breakpoint(min1920) {
      //   max-height: 315px;
      // }
      tr{
        display: flex;
      }
      tr td{
        width: 25%;
        color: #6b6b6b;
        font-size: 14px;
        &:last-of-type{
          width:50%;
        }
      }
      // @include breakpoint(max1366) {
      //   max-height: 230px;
      // }
    }
  }
}
.required {
  font-size: pxToRem(10px);
  line-height: 16px;
  letter-spacing: 0.33px;
  color: $grey-color4;
  .error {
    color: $red-color;
  }
}
.clear-date-time-class-error {
  .form-check-input {
    height: 1em;
    border: 1px solid rgba(0,0,0,.25);
  }
}

//global search styles
.gs-error-msg {
  position: absolute;
  font-size: 10px;
  left: 2px;
  bottom: -20px;
  color: #C6262D;
}
.gs-filter-wrapper {
  .filter-title {
    &::after {
      display: inline-block !important;
      padding-left: 6px;
      content: "\e91f";
      font-family: 'icomoon';
      font-size: 6px;
      color: #919191;
      border: none;
    }
    &:focus,
    &:active {
      background: transparent;
    }
    .filter-icon::before { font-size: 14px;}
  }
 
  .filter-reset-btn { 
    margin-bottom: 10px; 
    .st-reset-all-btn {
      font-size: pxToRem(12px) !important;
      font-family: $roboto-regular;
    }
  }
  .dropdown-menu {
    padding: 15px;
    min-width: 185px;
    border-radius: 0;
    box-shadow: 19px 28px 26px -31px rgba(0, 0, 0, 0.75);
  }
 .st-filter-checkbox-wrapper {
    margin-bottom: 15px;
    .ml-checkmark { 
      border-width: 1px;
      height: 14px;
      width: 14px;
      border-radius: 2px;
      &::after {
        left: 5px;
        top: 2px;
        width: 5px;
        height: 8px;
      }
    }
    label {
      padding: 0 0 0 25px !important;
      line-height: normal;
      font-family: $roboto-regular;
    }
 }
}
.header-right {
  .sch-btn-transparent {
    padding: 0;
    background: transparent;
    border: none;
  }
}
.reset-grid-filter::before {
  font-size: pxToRem(18px);
}
.admin-page {
  .ad-grid-wrapper .e-grid .e-toolbar .e-toolbar-items .e-toolbar-right .e-toolbar-item.e-cc-toolbar {
    right: 0;
  }
}
